import Vue from 'vue'
import App from './App.vue'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import router from './router'
import './plugins/base'
import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import VueMeta from 'vue-meta'
import i18n from './i18n'
import helpers from '@/mixins/helpers'
import QRious from 'qrious'
import Axios from 'axios'
import './registerServiceWorker'
import algoliasearch from 'algoliasearch'
import { PushNotifications } from '@capacitor/push-notifications'
import { Badge } from '@capawesome/capacitor-badge'
import { onAuthStateChanged, signOut, deleteUser } from 'firebase/auth'
import { db, databaseProduction, databaseDevelopment, auth, storage } from './firebaseConfig'
import { doc, updateDoc, onSnapshot, setDoc, query, collection, orderBy, where, or, and, limit, getDoc, connectFirestoreEmulator } from 'firebase/firestore'
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions'
import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage'
import { Capacitor } from '@capacitor/core'
import VueObserveVisibility from 'vue-observe-visibility'
import { inject } from '@vercel/analytics'
import { DeviceUUID } from 'device-uuid'
import { StatusBar, Style } from '@capacitor/status-bar'
import VueGtag from 'vue-gtag'
import { USE_DEV_API, USE_DEV_FIREBASE, FORCE_ENTERPRISE, FORCE_ENTERPRISE_ID } from '../config.js'



const APP_VERSION = '2.5.3'
const DIST_VERSION = '1'

Vue.use(VueMeta)
Vue.use(VueObserveVisibility)

if(process.env.VUE_APP_MODE === 'preview'){console.log('process.env.VUE_APP_MODE:', process.env.VUE_APP_MODE)}

Vue.config.performance = process.env.NODE_ENV !== 'production'
Vue.config.productionTip = false

// Initialize Vercel analytics
inject()

// Initialize Sentry
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    dsn: "https://5fa597e43e644b05b86c292fbf21ac9f@o1265013.ingest.sentry.io/4504533775089664",
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: [
          'localhost',
          'incept3d.com',
          /^[^.]+\.formfactories\.com$/,
          /^\//,
        ],
      }),
      new Sentry.Replay()
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    release: `formfactories@${APP_VERSION}`,
    dist: DIST_VERSION,
  })
}

function setTheme (theme) {
  if (theme) {
    document.documentElement.setAttribute('data-theme', theme)
    localStorage.setItem('prefs_brand', theme)
  } else {
    document.documentElement.removeAttribute('data-theme')
    localStorage.removeItem('prefs_brand')
  }
}

// Get default enterprise for domain
function getDomainEnterprise () {
  return httpsCallable(functions, 'getEnterprise')({
    domain: window.location.origin,
    mobile: Capacitor.isNative,
    pathname: window.location.pathname,
  }).then(res => res.data)
}

// Determine which enterprise to use
function getEnterprise () {
  const override = window.localStorage.getItem('enterpriseOverride')
  if (FORCE_ENTERPRISE) {
    if(process.env.VUE_APP_MODE === 'preview') {
      console.log('FORCE_ENTERPRISE:', FORCE_ENTERPRISE, 'FORCE_ENTERPRISE_ID:', FORCE_ENTERPRISE_ID)
    }
    return getDoc(doc(db, 'enterprises', FORCE_ENTERPRISE_ID)).then(snap => {
      if (snap.exists()) return { id: snap.id, enterprise: snap.data(), preloadedTasks: [], }
      else return getDomainEnterprise()
    })
  } else if (override) {
    return getDoc(doc(db, 'enterprises', override)).then(snap => {
      if (snap.exists()) return { id: snap.id, enterprise: snap.data(), preloadedTasks: [], }
      else {
        window.localStorage.removeItem('enterpriseOverride')
        return getEnterprise()
      }
    }) 
  } else return getDomainEnterprise()
  
}

// Setup firebase functions, and
// get enterprise for this session
const timeStart = Date.now()
const functions = getFunctions()
if (process.env.NODE_ENV !== 'production') {
  // connectFirestoreEmulator(db, 'localhost', 8888)
  // connectFunctionsEmulator(functions, 'localhost', 5001)
}
getEnterprise()
  .then(enterprise => {
    window.enterpriseID = enterprise.id
    window.enterprise = enterprise
    window.preloadedTasks = enterprise.preloadedTasks

    // Load google tag manager
    if (enterprise.enterprise.googleAnalyticsMeasurementID) {
      Vue.use(VueGtag, {
        config: { id: enterprise.enterprise.googleAnalyticsMeasurementID }
      })
      // Use this.$gtag.event('login', { method: 'Google' }) for recording events
    }
    
    // Handle new auth state change
    onAuthStateChanged(auth, async function (user) {
      const previousAuth = app ? app.currentUser : null
      const userToken = user ? await auth.currentUser.getIdTokenResult() : null
      
      // Identify user in Sentry
      if (user) Sentry.setUser({ id: user.uid, email: user.email })
      else Sentry.setUser(null)

      // Send user info to Vue app scope
      if (app) app.userToken = userToken
      if (app) app.currentUser = user

      // Send user info to window scope
      window.userToken = userToken
      window.currentUser = user

      // Route user away from login if needed
      if (app && user && app.$route.name === 'Login' && !app.showProjectPreview) {
        app.$router.push(app.$route.query.redirect || (app.isStaff ? '/' : '/projects'))
      }

      // Launch app if not yet started
      if (!app) {
        const tempTheme = localStorage.getItem('prefs_brand')
        if (tempTheme) setTheme(tempTheme)
        document.getElementById('intital-loading-icon').style.opacity = 0
        initializeVueApp(enterprise, user, userToken, tempTheme)
      }

      // Reset user profile listener
      if (app.subscriptions.profile) app.subscriptions.profile()
      app.subscriptions.profile = null

      // Get user-relevant data
      if (user) {
        // Ask for push notification permission
        if (Capacitor.isNativePlatform()) {
          // Request permission to use push notifications
          // iOS will prompt user and return if they granted permission or not
          // Android will just grant without prompting
          PushNotifications.requestPermissions().then(result => {
            if (result.receive === 'granted') {
              // Register with Apple / Google to receive push via APNS/FCM
              PushNotifications.register()
            } else {
              // Show some error
              console.log('Error requesting notification permissions', result)
            }
          })
        }

        // Record device/version info
        try {
          const deviceID = new DeviceUUID().get()
          const deviceInfo = new DeviceUUID().parse()
          setDoc(
            doc(db, 'enterprises', app.enterpriseID, 'users', user.uid, 'deviceStatistics', deviceID),
            Object.assign(JSON.parse(JSON.stringify(deviceInfo)), { loaded: Date.now(), version: app.version }),
            { merge: true },
          ).catch(err => console.log('Error recording device info:', err))
        } catch (err) {
          console.log('Error verifying version info:', err)
        }

        // Update push notification registration tokens to account
        if (app.notificationToken) {
          setDoc(
            doc(db, 'users', app.currentUser.uid, 'devices', app.notificationToken),
            { active: true, dateModified: Date.now() },
            { merge: true },
          ).catch(err => console.log('Error updating notification token:', err))
        }

        // Reset user profile listener
        app.subscriptions.profile = onSnapshot(
          doc(db, 'enterprises', app.enterpriseID, 'users', user.uid),
          docSnap => {
            if (docSnap.data()) {
              // Import user data if up-to-date
              if (!app.userProfile || app.userProfile.dateModified < (docSnap.data().dateModified ? docSnap.data().dateModified : 1)) {
                app.state.skipChange.userProfile = true
                app.userProfile = docSnap.data()
              }

              // Refresh token if requested
              if (docSnap.data().tokenExpired) app.resetToken(user)

              // Fill in missing preferences
              if (!app.userProfile.email) Vue.set(app.userProfile, 'email', app.currentUser.email)
              if (!app.userProfile.prefs_dashboard) Vue.set(app.userProfile, 'prefs_dashboard', 'production')
              if (app.userProfile.prefs_brand && !app.enterprise.brands.find(b => b.id === app.userProfile.prefs_brand && b.public)) Vue.set(app.userProfile, 'prefs_brand', '')
              if (app.userProfile.prefs_taskView === false) Vue.set(app.userProfile, 'prefs_inlineTasks', true)
              if (!app.userProfile.prefs_messageTypes) {
                app.userProfile.prefs_messageTypes = {
                  'message': { text: 'Messages', value: 'message', enabled: true },
                  'event': { text: 'Events', value: 'event', enabled: true  },
                  'email': { text: 'Emails', value: 'email', enabled: true  },
                  'machineEvent': { text: 'Machine Events', value: 'machineEvent', enabled: true },
                }
              }
            } else {
              setDoc(doc(db, 'enterprises', app.enterpriseID, 'users', user.uid), { email: user.email }, { merge: true }).catch(err => console.log('Error creating user profile:', err))
            }
          },
          err => console.log('Error in user profile snapshot listening:', err),
        )

        app.listenToNotifications()

        if (app.isStaff) {
          app.listenToTasks()
          app.listenToStarredNotifications()
          app.listenToStaffList()
          app.listenToManagement()
          app.listenToMachines()
          app.listenToUnreadNotifications()
        }
      } else if (previousAuth) {
        // Route user to login page
        if (!['Submit Files', 'Login', 'ProjectPage', null].includes(app.$route.name) && !app.showProjectPreview) {
          app.$router.push('/login')
        }
        Object.values(app.subscriptions).forEach(unsub => {
          if (Array.isArray(unsub)) {
            unsub.forEach(u => u())
          } else if (typeof unsub === 'function') unsub()
        })
        signOut(auth).then(() => {
          app.currentUser = null
          window.currentUser = null
          app.userToken = null
          window.userToken = null
          app.userProfile = null
        })
        .catch(err => console.log(err))
          app.management = null
          app.allProjects = null
          app.state.settingsOpen = false
        }
    })
  })

// Function to initialize main Vue app
let app
function initializeVueApp (startingEnterprise, user, userToken, tempTheme) {
  app = new Vue({
    router,
    vuetify,
    i18n,
    mixins: [helpers],
    metaInfo () {
      let name = this.brandName || 'formfactories'
      if (this.userNotifications.filter(n => !n.read).length) name += ` ('${this.userNotifications.filter(n => !n.read).length})`

      return {
        title: name,
        titleTemplate: `%s | ${name}`,
        meta: [
          { name: 'application-name', content: name },
          { name: 'apple-mobile-web-app-title', content: name },
          { name: 'msapplication-TileColor', content: this.theme.color },
          { name: 'msapplication-TileImage', content: this.favicon ? this.shrunkImage(this.favicon, 150, 150, 'png') : require('@/assets/favicons/favicon_150.png') },
          { name: 'msapplication-config', content: '@/assets/favicons/incept3d/browserconfig.xml' },
          { name: 'theme-color', content: this.theme.headerColor },
        ],
        link: [
          { rel: 'shortcut icon', sizes: '180x180', href: this.favicon ? this.shrunkImage(this.favicon, 180, 180, 'png') : require('@/assets/favicons/favicon_180.png') },
          { rel: 'apple-touch-icon', sizes: '180x180', href: this.favicon ? this.shrunkImage(this.favicon, 180, 180, 'png') : require('@/assets/favicons/favicon_180.png') },
          { rel: 'icon', type: 'image/png', sizes: '240x240', href: this.favicon ? this.shrunkImage(this.favicon, 240, 240, 'png') : require('@/assets/favicons/favicon_240.png') },
          { rel: 'icon', type: 'image/png', sizes: '192x192', href: this.favicon ? this.shrunkImage(this.favicon, 192, 192, 'png') : require('@/assets/favicons/favicon_192.png') },
          { rel: 'icon', type: 'image/png', sizes: '180x180', href: this.favicon ? this.shrunkImage(this.favicon, 180, 180, 'png') : require('@/assets/favicons/favicon_180.png') },
          { rel: 'icon', type: 'image/png', sizes: '144x144', href: this.favicon ? this.shrunkImage(this.favicon, 144, 144, 'png') : require('@/assets/favicons/favicon_144.png') },
          { rel: 'icon', type: 'image/png', sizes: '128x128', href: this.favicon ? this.shrunkImage(this.favicon, 128, 128, 'png') : require('@/assets/favicons/favicon_128.png') },
          { rel: 'icon', type: 'image/png', sizes: '96x96', href: this.favicon ? this.shrunkImage(this.favicon, 96, 96, 'png') : require('@/assets/favicons/favicon_96.png') },
          { rel: 'icon', type: 'image/png', sizes: '48x48', href: this.favicon ? this.shrunkImage(this.favicon, 48, 48, 'png') : require('@/assets/favicons/favicon_48.png') },
          { rel: 'icon', type: 'image/png', sizes: '32x32', href: this.favicon ? this.shrunkImage(this.favicon, 32, 32, 'png') : require('@/assets/favicons/favicon_32.png') },
          { rel: 'icon', type: 'image/png', sizes: '16x16', href: this.favicon ? this.shrunkImage(this.favicon, 16, 16, 'png') : require('@/assets/favicons/favicon_16.png') },
          // { rel: 'mask-icon', href: this.enterprise.safariPinnedTab || require('@/assets/favicons/incept3d/safari-pinned-tab.svg'), color: this.theme.color },
        ],
      }
    },
    data: function () {
      return {
        version: APP_VERSION,
        bugReportingChat: 'tVmBdZqiDmaCGyIeML4t',
        apiPath: USE_DEV_API ? 'https://api.lanalanalana.com' : 'https://api.formfactories.com',
        productionMode: process.env.NODE_ENV === 'production',
        sitePath: document.location.origin,
        db: db,
        auth: auth,
        storage: storage,
        functions: functions,
        publicPath: process.env.BASE_URL,
        showStaffSideBar: false,
        enterpriseRaw: startingEnterprise.enterprise,
        enterpriseID: startingEnterprise.id,
        allTiers: [],
        worker: null,
        memoryHistory: [],
        memorySamples: 600,
        memoryInterval: null,
        workerTasks: {},
        primaryProjectStatus: null,
        initialChat: null,
        droppedFiles: null,
        navigation: null,
        openDesignCollectionChat: null,
        activeUploads: 0,
        showProjectPreview: false,
        projectPreviewNumItems: 0,
        production: {},
        currentUser: user,
        userToken: userToken,
        userProfile: null,
        cartOpen: false,
        userNotifications: [],
        starredUserNotifications: [],
        unreadUserNotifications: [],
        userTaskGroups: [],
        userReads: {},
        staffInviteID: null,
        acceptingStaffInvite: false,
        anonymousUserName: '',
        anonymousUserEmail: '',
        quantityPreviews: [1, 5, 10, 50, 100],
        printerSnapshotBrowser: false,
        printerSnapshotFullscreen: false,
        printerSnapshotFullscreenPrinter: null,
        printerSnapshotFullscreenFamily: null,
        printerSnapshotPrinterTab: 1,
        publishedTasks: startingEnterprise.preloadedTasks,
        taxRates: [],
        machinePanelID: null,
        machinePanelOpen: false,
        pageScroll: 0,
        publicConfiguration: {},
        configPublic: {},
        messageToScrollTo: null,
        messageToScrollToChat: null,
        errors: [],
        visibleTaskListener: null,
        viewableTasks: [],
        simulateTeams: false,
        simulatedTeams: ['public'],
        spaceDown: false,
        spaceHoldDeadline: null,
        messageDrafts: {},
        queuedFailureEvent: null,
        machineWebsocketsSubscriptions: {},
        taskElementTypes: [
          {
            name: 'Text',
            type: 'text',
            icon: 'fas fa-align-left',
            active: true,
            simpleMode: true,
            advanced: false,
          },
          {
            name: 'Header',
            type: 'header',
            icon: 'fas fa-heading',
            active: false,
            simpleMode: false,
            advanced: false,
          },
          {
            name: 'Subtitle',
            type: 'subtitle',
            icon: 'mdi-format-header-2',
            active: false,
            simpleMode: false,
            advanced: false,
          },
          {
            name: 'Big Header',
            type: 'h1',
            icon: 'mdi-format-header-1',
            active: true,
            simpleMode: true,
            advanced: false,
          },
          {
            name: 'Medium Header',
            type: 'h2',
            icon: 'mdi-format-header-2',
            active: true,
            simpleMode: false,
            advanced: false,
          },
          {
            name: 'Small Header',
            type: 'h3',
            icon: 'mdi-format-header-3',
            active: true,
            simpleMode: false,
            advanced: false,
          },
          {
            name: 'Todo',
            type: 'todo',
            icon: 'fas fa-check-circle',
            active: true,
            simpleMode: true,
            advanced: false,
          },
          {
            name: 'Bullet',
            type: 'bullet',
            icon: 'mdi-circle',
            active: true,
            simpleMode: true,
            advanced: true,
          },
          {
            name: 'Counter',
            type: 'counter',
            icon: 'fas fa-calculator',
            active: true,
            simpleMode: true,
            advanced: true,
          },
          {
            name: 'Colors',
            type: 'colors',
            icon: 'fas fa-tint',
            active: false,
            simpleMode: false,
            advanced: true,
          },
          {
            name: 'Contents',
            type: 'contents',
            icon: 'fas fa-bookmark',
            active: true,
            simpleMode: false,
            advanced: true,
          },
          {
            name: 'Reference',
            type: 'reference',
            icon: 'fas fa-asterisk',
            active: true,
            simpleMode: false,
            advanced: true,
          },
          {
            name: 'Group',
            type: 'group',
            icon: 'fas fa-object-group',
            active: true,
            simpleMode: false,
            advanced: false,
          },
          {
            name: 'Component',
            type: 'component',
            icon: 'fas fa-cog',
            active: true,
            simpleMode: false,
            advanced: true,
          },
        ],
        salesOptions: null,
        machinesState: {},
        sellingPoints: {},
        management: null,
        allMachineInstances: [],
        showAdvanced: true,
        showNotifications: false,
        showTasks: false,
        notificationAmount: 6,
        notificationPage: 2,
        currentTime: Date.now(),
        currentTimeMinutes: Date.now(),
        currentTimeInterval: null,
        currentTimeMinutesInterval: null,
        unreads: {},
        active: {},
        tempTheme: tempTheme,
        hideHeaders: false,
        notificationToken: null,
        organizationPreviewOpen: false,
        organizationPreviewID: null,
        userPreviewOpen: false,
        userPreviewEmail: null,
        projectPreviewOpen: false,
        projectPreviewID: null,
        jobPreviewOpen: false,
        jobPreviewID: null,
        jobPreviewListener: null,
        jobPreviewData: null,
        showAutoEmailConfirmation: false,
        autoEmailConfirmation: null,
        autoEmailConfirmationQueue: [],
        autoEmailConfirmationSending: false,
        autoEmailConfirmationDownloading: false,
        emailEditorShow: false,
        emailEditorProject: null,
        emailEditorTemplate: null,
        staffEnterprisesLoaded: false,
        staffEnterpriseListeners: {},
        staffEnterpriseNotificationListeners: {},
        staffEnterpriseNames: {},
        staffEnterpriseURLs: {},
        staffEnterpriseUnreadNotificationCount: {},
        adjustingCustomPricing: false,
        emailAttachmentTags: [
          { text: 'Quote', value: 'quote', condition: project => this.enableProjectPricing },
          { text: 'Packing Slip', value: 'packingSlip', condition: project => true },
          { text: 'Invoice', value: 'invoice', condition: project => this.enableProjectPricing && project.checkout },
          { text: 'Purchase Order', value: 'purchaseOrder', condition: project => project.checkout && project.checkout.files && project.checkout.files.length },
        ],
        emailReplacements: {
          index: {
            description: 'Raw Project Index, e.g. 2123',
            replacer: project => project.index,
          },
          'quote-number': {
            description: 'Client-Formatted Project Number, e.g. LNA-42123',
            replacer: project => `LNA-${parseInt(project.index) + 42000}`,
          },
          quantities: {
            description: 'Project Description, e.g. 2 models, 8 units, White PLA',
            replacer: project => this.projectDescription(project),
          },
          'total-price': {
            description: 'Subtotal of order (with $ prepended)',
            replacer: project => '$' + this.totals(project).subtotal,
          },
          'delivery-info': {
            description: 'If pickup, says "and is ready for pick-up at our address:", otherwise "and is shipping to:"',
            replacer: project => project.checkout ? (project.pickup ? 'and is ready for pick-up at our address:' : 'and is shipping to:') : '',
          },
          'delivery-address': {
            description: 'Lists the delivery address of the customer (or your business address if pickup)',
            replacer: project => project.checkout ? (project.pickup ? this.$root.enterprise.address.street1 + (this.$root.enterprise.address.street2 ? '<br>' + this.$root.enterprise.address.street2 : '') + '<br>' + this.$root.enterprise.address.city + ', ' + this.$root.enterprise.address.state + ' ' + this.$root.enterprise.address.zip
            : project.checkout.shipping.name + '<br>' +
              project.checkout.shipping.address1 +
              (project.checkout.shipping.address2 ? '<br>' + project.checkout.shipping.address2 + '<br>' : '<br>') +
              project.checkout.shipping.city + ', ' +
              project.checkout.shipping.state + ' ' +
              project.checkout.shipping.zip) : '',
          },
          'due-date': {
            description: 'Due date of project',
            replacer: project => {
              try {
                return new Intl.DateTimeFormat('en-US', { 
                  day: '2-digit', 
                  month: '2-digit', 
                  year: '2-digit',
                  timeZone: 'UTC'
                 }).format(new Date(project.dueDate))
              } catch (error) {
                console.error('Error formatting due date', error)
                return null
              }
            }
          },
          'project-url': {
            description: 'The URL to this project',
            replacer: (project, uuid) => document.location.origin + '/projects/' + uuid,
          },
          'project-link': {
            description: 'A link to the project formatted as the project index',
            replacer: (project, uuid) => `<a href="${document.location.origin}/projects/${uuid}">LNA-${parseInt(project.index) + 42000}</a>`,
          },
          'project-email': {
            description: 'The project-level customer email',
            replacer: project => [project.clientEmail].concat(project.extraEmails || []).join(', ') || 'Not provided',
          },
          'project-company': {
            description: 'The project-level customer company',
            replacer: project => project.clientCompany || 'Not provided',
          },
          'project-name': {
            description: 'The project-level customer name',
            replacer: (project, uuid, account) => {
              let name = ''
              if (account && account.staffProfile && account.staffProfile.fullName) name = account.staffProfile.fullName
              else if (project.clientName) name = project.clientName
              else if (account && account.userProfile && account.userProfile.fullName) name = account.userProfile.fullName
              return name
            },
          },
          'project-first-name': {
            description: 'The first word of the project-level customer name',
            replacer: (project, uuid, account) => {
              let name = ''
              if (account && account.staffProfile && account.staffProfile.fullName) name = account.staffProfile.fullName
              else if (project.clientName) name = project.clientName
              else if (account && account.userProfile && account.userProfile.fullName) name = account.userProfile.fullName
              return name.trim().split(' ')[0]
            },
          },
          'project-phone': {
            description: 'The project-level customer phone',
            replacer: project => project.clientPhone || 'Not provided',
          },
          'project-leadtime': {
            description: 'The project lead time',
            replacer: project => project.leadDays || '',
          },
          'project-debug': {
            description: 'Debug data for the nerds',
            replacer: project => JSON.stringify(project),
          },
          'company-email': {
            description: 'Your company support email',
            replacer: () => this.enterprise.email || 'support@formfactories.com',
          },
          'currentuser-fullname': {
            description: 'Full name of the person sending the email',
            replacer: () => this.userProfile.fullName || '',
          },
          'currentuser-firstname': {
            description: 'First name of the person sending the email',
            replacer: () => (this.userProfile.fullName || '').split(' ')[0],
          },
          'currentuser-phone': {
            description: 'Phone of the person sending the email',
            replacer: () => this.userProfile.phone,
          },
          'currentuser-email': {
            description: 'Email of the person sending the email',
            replacer: () => this.currentUser.email,
          },
          'salesperson1-fullname': {
            description: 'Full name of the first salesperson assigned to the project',
            replacer: project => {
              if (project.roles && project.roles.sales && project.roles.sales[0]) {
                this.loadUserInfo(project.roles.sales[0])
                return this.lookup.users[project.roles.sales[0]].fullName
              } else return null
            },
          },
          'salesperson1-firstname': {
            description: 'First name of the first salesperson assigned to the project',
            replacer: project => {
              if (project.roles && project.roles.sales && project.roles.sales[0]) {
                this.loadUserInfo(project.roles.sales[0])
                return (this.lookup.users[project.roles.sales[0]].fullName || '').split(' ')[0]
              } else return null
            },
          },
          'salesperson1-phone': {
            description: 'Phone of the first salesperson assigned to the project',
            replacer: project => {
              if (project.roles && project.roles.sales && project.roles.sales[0]) {
                this.loadUserInfo(project.roles.sales[0])
                return this.lookup.users[project.roles.sales[0]].phone
              } else return null
            },
          },
          'salesperson1-email': {
            description: 'Email of the first salesperson assigned to the project',
            replacer: project => {
              if (project.roles && project.roles.sales && project.roles.sales[0]) {
                this.loadUserInfo(project.roles.sales[0])
                return this.lookup.users[project.roles.sales[0]].email
              } else return null
            },
          },
          'salesperson1-calendar': {
            description: 'Calendar URL of the first salesperson assigned to the project',
            replacer: project => {
              if (project.roles && project.roles.sales && project.roles.sales[0]) {
                this.loadUserInfo(project.roles.sales[0])
                return this.lookup.users[project.roles.sales[0]].calendarUrl
              } else return null
            },
          },
          trackingnumber1: {
            description: 'The first tracking number added to the project',
            replacer: project => {
              if (project.shipments && project.shipments[0]) return project.shipments[0].tracking_number
              else return null
            },
          },
          'trackingnumber1-url': {
            description: 'URL to track the first tracking number added to the project',
            replacer: project => {
              if (project.shipments && project.shipments[0]) return project.shipments[0].tracking_url_provider
              else return null
            },
          },
          'trackingnumber1-provider': {
            description: 'Shipping provider of the first tracking number added to the project',
            replacer: project => {
              if (project.shipments && project.shipments[0] && project.shipments[0].rate_details) {
                return project.shipments[0].rate_details.provider
              } else return null
            },
          },
          'po-number': {
            description: 'The number of a Purchase Order submitted at checkout',
            replacer: project => project.checkout ? project.checkout.po : null,
          },
          'checkout-po-email': {
            description: 'Accounts payable email from checkout (if available)',
            replacer: project => project.checkout ? (project.checkout.email || '') : '',
          },
          'checkout-po-phone': {
            description: 'Accounts payable phone from checkout (if available)',
            replacer: project => project.checkout ? (project.checkout.phone || '') : '',
          },
          'checkout-po-name': {
            description: 'Accounts payable name from checkout (if available)',
            replacer: project => project.checkout ? (project.checkout.name || '') : '',
          },
          'checkout-billing-name': {
            description: 'Bill-to name from checkout (if available)',
            replacer: project => project.checkout ? ((project.checkout.billing || {}).name || '') : '',
          },
          'checkout-billing-first-name': {
            description: 'The first word of the bill-to name from checkout (if available)',
            replacer: project => project.checkout ? ((project.checkout.billing || {}).name || '').trim().split(' ')[0] : '',
          },
          'checkout-billing-company': {
            description: 'Bill-to company from checkout (if available)',
            replacer: project => project.checkout ? ((project.checkout.billing || {}).company || '') : '',
          },
          'checkout-billing-phone': {
            description: 'Bill-to phone from checkout (if available)',
            replacer: project => project.checkout ? ((project.checkout.billing || {}).phone || '') : '',
          },
          'quote-rejection-reason': {
            description: 'Quote Rejection Reason (if available)',
            replacer: project => project.quoteRejectionReason || 'Unspecified',
          },
          'chat-subscriber-name': {
            description: 'Name of customer that is receiving a chat email notification',
            replacer: data => data.chatSubscriberName || '',
          },
          'chat-subscriber-topic': {
            description: 'Topic of the email notification a customer is getting for a new message',
            replacer: data => data.chatSubscriberTopic || '',
          },
          'chat-subscriber-messages': {
            description: 'Preview of the messages an email notification is getting sent for',
            replacer: data => {
              const messages = (data.chatSubscriberMessages || []).sort((a, b) => a - b)
              const namesToGet = [...new Set(messages.map(x => x.sender).filter(x => x))]
              namesToGet.forEach(n => this.loadUserInfo(n))
              const nameLookup = {}
              namesToGet.forEach((n, i) => { nameLookup[n] = this.lookup.users[n].fullName })
              const messageHTML = messages.map(m => {
                let string = ''
                string += `<p><u><strong>${((m.sender ? nameLookup[m.sender] : m.anonymousUserName) || 'Someone')}</strong> at ${new Date(m.created).toLocaleString()}:</u></p>`
                string += `<div>${m.content}</div>`
                return string
              }).join('<br><br>')
              return messageHTML
            },
          },
          'chat-subscriber-link': {
            description: 'URL to the chat with the new messages a customer is being notified about',
            replacer: data => data.chatSubscriberLink || document.location.origin,
          },
        },
        lists: [],
        lookup: {
          users: {},
          chatRooms: {},
          zohoContacts: {},
          organizations: {},
          accounts: {},
          jobs: {},
        },
        state: {
          activeProject: null,
          skipChange: {
            userProfile: false,
            settingsOpen: false,
          },
          models: {},
          uploadingProfilePic: false,
          settingsOpen: false,
        },
        subscriptions: {},
        machineStateServerSocket: null,
        darkModePreference: false,
        algoliaClient: null,
        searchProjectIndex: null,
        searchUserIndex: null,
        searchOrganizationIndex: null,
        searchChatIndex: null,
        searchJobIndex: null,
        modelExtensions: ['stl', 'obj', 'step', 'stp', 'sldprt', 'asm', 'f3d', 'fbx', 'iam', 'ipt', 'neu', 'prt', 'sldasm', 'smb', 'smt', 'stpz', 'wire', 'x_b', 'x_t', '3mf'],
        allTeams: [],
        staff: [],
        showTaskReader: false,
        readerTask: null,
        readerElement: null,
        totalReaderTasks: 0,
        enterpriseOverride: window.localStorage.getItem('enterpriseOverride'),
        domainEnterprise: { text: 'Default', value: null, enterpriseID: null },
        localRecentProject: null,
        cachedStripeTaxCodes: null,
        cachedStripeTaxCodesRetrieved: null,
        cachedStripeTaxCodesPromise: null,
        forceEnterpriseOverrideSelection: false,
        tasksThatNeedOpening: [],
        materialProperties: [
          {
            text: 'Economical',
            value: 'cost',
            color: 'green',
            icon: 'fas fa-dollar-sign',
            min: 120,
            max: 20,
          },
          {
            text: 'Turnaround Speed',
            value: 'turnaround',
            color: 'blue',
            icon: 'fas fa-shipping-fast',
          },
          {
            text: 'Flexibility',
            value: 'flexibility',
            color: 'pink',
            icon: 'fas fa-rainbow',
            min: 4.5,
            max: 0,
          },
          {
            text: 'Strength',
            value: 'strength',
            color: 'amber',
            icon: 'fas fa-dumbbell',
          },
          {
            text: 'Heat Resistance',
            value: 'temperatureResistance',
            color: 'red',
            icon: 'fas fa-temperature-high',
            min: 20,
            max: 208,
          },
        ],
        printerStates: {
          C: { text: 'Updating Configuration', color: '#AB47BC' },
          I: { text: 'Idle', color: 'dodgerblue' },
          B: { text: 'Busy', color: '#FFC107' },
          P: { text: 'Printing', color: 'limegreen' },
          D: { text: 'Pausing', color: '#CDDC39' },
          S: { text: 'Paused', color: '#FF9800' },
          R: { text: 'Resuming', color: '#CDDC39' },
          H: { text: 'Stopped', color: 'red' },
          F: { text: 'Flashing', color: '#9C27B0' },
          T: { text: 'Changing Tool', color: '#009688' },
          X: { text: 'Disconnected', color: '#F44336' },
          E: { text: 'Error', color: '#F44336' },
          U: { text: 'Unknown', color: '#607D8B' },
          O: { text: 'Offline', color: '#9E9E9E' },
        },
      }
    },
    computed: {
      publicTaskItems: function () {
        return this.$root.publicTasks.map(x => {
          return {
            text: this.$root.taskName(x) === 'formfactories' ? 'Home Page' : this.$root.taskName(x),
            value: x.uuid,
          }
        })
      },
      machineWebsocketSubscriptionKey: function () {
        const byID = {}
        Object.keys(this.machineWebsocketsSubscriptions).forEach(subID => {
          const sub = this.machineWebsocketsSubscriptions[subID]
          const mid = sub.id
          if (!byID[mid]) byID[mid] = new Set()
          sub.scope.forEach(s => byID[mid].add(s))
        })
        return Object.keys(byID).map(mid => mid + ':' + [...byID[mid]].join(','))
      },
      canUseEmailEditor () {
        if (!this.isStaff) return false
        if (this.isExecutive) return true
        return this.isInTeams(this.configPublic?.emailEditorTeams || [])
      },
      canSeePricing () {
        if (!this.enableProjectPricing) return false
        if (!this.isStaff) return false
        if (this.isExecutive) return true
        return this.isInTeams(this.configPublic?.pricingTeams || [])
      },
      canEditMachines () {
        if (!this.isStaff) return false
        if (this.isExecutive) return true
        return this.isInTeams(this.configPublic?.machineEditorTeams || [])
      },
      canCreateTaskCategories () {
        if (!this.isStaff) return false
        if (this.isExecutive) return true
        return this.isInTeams(this.configPublic?.taskCategoryCreators || [])
      },
      canEditCatalog () {
        if (!this.isStaff) return false
        if (this.isExecutive) return true
        return this.isInTeams(this.configPublic?.catalogEditors || [])
      },
      canEditStoreProducts () {
        if (!this.$root.configPublic.enableStore) return false
        if (!this.isStaff) return false
        if (this.isExecutive) return true
        return this.isInTeams(this.configPublic?.storeProductEditors || [])
      },
      maxMemorySample: function () {
        return this.memoryHistory.length ? this.memoryHistory[this.memoryHistory.length - 1].jsHeapSizeLimit : 1
      },
      showMemory: {
        get: function () {
          return !!this.$root.userProfile?.prefs_showMemory && window.performance.memory
        },
        set: function (value) {
          this.$set(this.$root.userProfile, 'prefs_showMemory', value)
        },
      },
      defaultExtensions: function () {
        return {
          models: ['stl', 'obj', 'step', 'stp', 'sldprt', 'asm', 'f3d', 'fbx', 'iam', 'ipt', 'neu', 'prt', 'sldasm', 'smb', 'smt', 'stpz', 'wire', 'x_b', 'x_t', 'dxf', 'cdr', 'ai', 'svg'],
          jobs: [...new Set(this.allMachines.flatMap(m => (m.extension || '').split(',').map(x => x.toLowerCase().replace('.', '').trim()).filter(x => x)))],
          linked: ['factory', '3mf', 'png', 'jpeg', 'jpg'],
        }
      },
      defaultCustomerExtensions: function () {
        return {
          models: ['stl', 'obj', 'step', 'stp', 'sldprt', 'asm', 'f3d', 'fbx', 'iam', 'ipt', 'neu', 'prt', 'sldasm', 'smb', 'smt', 'stpz', 'wire', 'x_b', 'x_t', 'dxf', 'cdr', 'ai', 'svg'],
          jobs: [],
          linked: [],
        }
      },
      extensions: function () {
        const extensions = this.defaultExtensions
        const overrides = this.configPublic?.fileTypeBehavior || []
        overrides.forEach(o => {
          const ext = o.extension.toLowerCase().replace('.', '').trim()
          Object.keys(extensions).forEach(k => {
            if (extensions[k].includes(ext)) extensions[k] = extensions[k].filter(x => x !== ext)
          })
          if (extensions[o.behavior]) extensions[o.behavior].push(ext)
        })

        return extensions
      },
      customerExtensions: function () {
        const extensions = this.defaultCustomerExtensions
        const overrides = this.configPublic?.fileTypeBehavior || []
        overrides.forEach(o => {
          const ext = o.extension.toLowerCase().replace('.', '').trim()
          Object.keys(extensions).forEach(k => {
            if (extensions[k].includes(ext)) extensions[k] = extensions[k].filter(x => x !== ext)
          })
          if (extensions[o.customerBehavior]) extensions[o.customerBehavior].push(ext)
        })

        return extensions
      },
      formfactoriesPlan: function () {
        const currentPlan = this.allTiers.find(x => x.id === this.$root.enterprise.tier)
        if (!currentPlan) return null
        const overridePlan = (this.enterprise.customPlanPricing || {})[this.enterprise.tier] || {}
        if (overridePlan.price === '' || isNaN(overridePlan.price)) delete overridePlan.price
        return Object.assign({}, currentPlan, overridePlan)
      },
      formfactoriesPlanPrice: function () {
        if (!this.formfactoriesPlan) return null
        return +this.formfactoriesPlan.price
      },
      favicon: function () {
        if (this.isStaff) return this.brand.staffFavicon
        else return this.brand.userFavicon
      },
      showInviteDialog: {
        get: function () {
          return !!(this.userID && this.staffInviteID && !this.isStaff)
        },
        set: function (value) {
          if (!value) {
            this.staffInviteID = null
          }
        },
      },
      stripeCredentials: function () {
        if (!this.enterprise.stripeConnectAccountID) return
        return { stripeAccount: this.enterprise.stripeConnectAccountID }
      },
      allMachineInstancesByID: function () {
        const result = {}
        this.allMachineInstances.forEach(x => { result[x.id] = x })
        return result
      },
      userID: function () {
        return this.currentUser ? this.currentUser.uid : null
      },
      userTasks: function () {
        // Return all tasks in userTaskGroups, but filter out duplicates (by uuid)
        if (this.userTaskGroups.length === 0) return []
        if (this.userTaskGroups.length === 1) return this.userTaskGroups[0]

        const tasks = []
        this.userTaskGroups.forEach(g => {
          g.forEach(t => {
            if (!tasks.find(x => x.uuid === t.uuid)) tasks.push(t)
          })
        })
        return tasks
      },
      taskReaderTeams: function () {
        return this.simulateTeams ? this.simulatedTeams : this.myTeams.map(x => x.uuid)
      },
      tier: function () {
        return this.allTiers.find(t => !this.enterprise.tier || t.id === this.enterprise.tier) || { features: {} }
      },
      enableProjectPricing: function () {
        return this.tier.features.shopSupport && this.configPublic.enableProjectPricing
      },
      enablePublicChats: function () {
        return this.tier.features.publicChatRooms && this.configPublic.enablePublicChats
      },
      enablePrintQueue: function () {
        if (!this.$root.isStaff) return false
        if (!this.tier.features.printQueue) return false
        return this.configPublic.enablePrintQueue
      },
      enableTaskReader: function () {
        if (!this.tier.features.reader) return false
        if (!this.enterprise.enableTaskReader) return false
        return (!this.$root.enterprise.enableTaskReaderBetaOnly || this.betaMode)
      },
      enableShelves: function () {
        if (!this.$root.isStaff) return false
        if (!this.tier.features.shelves) return false
        if (!this.$root.enterprise.enableShelves) return false
        return (!this.$root.enterprise.enableShelvesBetaOnly || this.betaMode)
      },
      enableAutomatedPricing: function () {
        return this.enableProjectPricing && this.tier.features.automatedPricing
      },
      enableInventory: function () {
        if (!this.$root.isStaff) return false
        if (!this.tier.features.inventory) return false
        if (!this.$root.enterprise.enableInventory) return false
        return (!this.$root.enterprise.enableInventoryBetaOnly || this.betaMode)
      },
      betaMode: function () {
        return this.configPublic.enableStaffBetaOptIn && this.userProfile && this.userProfile.prefs_beta
      },
      staffEnterpriseOptions: function () {
        if (Capacitor.isNative) return this.staffEnterprises
        return [this.domainEnterprise].concat(this.staffEnterprises)
      },
      staffEnterpriseIDs: function () {
        return Object.keys(((this.userToken || {}).claims || {}).enterprises || {}).filter(e => this.userToken.claims.enterprises[e].staff)
      },
      staffEnterprises: function () {
        return this.staffEnterpriseIDs.map(id => ({
          text: this.staffEnterpriseNames[id] || 'Loading...',
          value: id,
          url: this.staffEnterpriseURLs[id] || '',
        }))
      },
      claims: function () {
        const legacyClaims = (this.userToken || {}).claims || {}
        const enterpriseClaims = (legacyClaims.enterprises || {})[this.enterpriseID]
        return enterpriseClaims || legacyClaims
      },
      enterprise: function () {
        return Object.assign(this.enterpriseRaw || {}, this.configPublic || {})
      },
      algoliaCredentials: function () {
        return {
          applicationID: (this.publicConfiguration || {}).algoliaApplicationID,
          searchKey: (this.management || {}).algoliaSearchKey,
        }
      },
      darkMode: function () {
        return this.darkModePreference && this.isStaff
      },
      secondaryCardColor: function () {
        const color = this.theme.cardColor || '#FFFFFF'
        const luminance = (0.299 * parseInt(color.substr(1, 2), 16) + 0.587 * parseInt(color.substr(3, 2), 16) + 0.114 * parseInt(color.substr(5, 2), 16)) / 255
        const backgroundColor = this.theme.backgroundColor
        const backgroundLuminance = (0.299 * parseInt(backgroundColor.substr(1, 2), 16) + 0.587 * parseInt(backgroundColor.substr(3, 2), 16) + 0.114 * parseInt(backgroundColor.substr(5, 2), 16)) / 255
        const lumDif = luminance - backgroundLuminance
        return this.blendColors(this.theme.cardColor, this.theme.backgroundColor, 0.010 / lumDif)
      },
      brandEmail: function () {
        if (this.brand && this.brand.email) return this.brand.email
        else return this.enterprise.email
      },
      brandName: function () {
        if (this.brand && this.brand.name && !this.brand.hideEnterpriseAttribution) return this.brand.name
        else return this.enterprise.name
      },
      brandPhone: function () {
        if (this.brand && this.brand.phone) return this.brand.phone
        else return this.enterprise.phone
      },
      showHeader: function () {
        return !(this.$route.name === 'Counting' && this.hideHeaders)
      },
      publicBrands: function () {
        return this.enterprise.brands.filter(b => b.public)
      },
      theme: function () {
        return this.darkMode ? this.darkTheme : this.lightTheme
      },
      darkTheme: function () {
        return this.isStaff ? this.brand.themes.staffDark : this.brand.themes.userDark
      },
      lightTheme: function () {
        return this.isStaff ? this.brand.themes.staffLight : this.brand.themes.userLight
      },
      brandProperties: function () {
        return {
          '--highlightColor': this.theme.color,
          '--headerColor': this.theme.headerColor,
          '--paneColor1': this.theme.cardColor,
          '--paneColor2': this.secondaryCardColor,
          '--cardColorDark': this.darkTheme.cardColor,
          '--cardColorLight': this.lightTheme.cardColor,
          '--headerTextColor': 'white',
        }
      },
      lightHeaderText: function () {
        const headerColor = this.theme.headerColor || '#FFFFFF'
        const luminance = (0.299 * parseInt(headerColor.substr(1, 2), 16) + 0.587 * parseInt(headerColor.substr(3, 2), 16) + 0.114 * parseInt(headerColor.substr(5, 2), 16)) / 255
        return luminance < 0.5
      },
      brand: function () {
        // Match user's brand preference if available
        if (this.isStaff && this.userProfile && this.userProfile.prefs_brand) {
          const themeMatch = this.enterprise.brands.find(b => b.id === this.userProfile.prefs_brand && b.public)
          if (themeMatch) return themeMatch
        }

        // Otherwise match user's current domain with matching enterprise domain
        let domainMatch
        const hostName = window.location.hostname.toLowerCase().trim()
        domainMatch = this.enterprise.domains.find(d => d.domain.toLowerCase().trim() === hostName)
        if (!domainMatch) {
          const fullHostName = window.location.hostname.split('.').slice(-2).join('.').toLowerCase().trim()
          domainMatch = this.enterprise.domains.find(d => d.domain.toLowerCase().trim() === fullHostName)
        }

        // Get any brand associated with the domain
        if (!domainMatch || !domainMatch.brand || !this.enterprise.brands.find(b => b.id === domainMatch.brand)) return this.enterprise.brands.find(b => b.default)
        else return this.enterprise.brands.find(b => b.id === domainMatch.brand)
      },
      tasksLeft: function () {
        const ids = [this.currentUser.uid].concat(this.myTeams.map(x => x.uuid))
        return this.userTasks
          .filter(t => t.members.includes(this.currentUser.uid))
          .map(t => new Set(ids.flatMap(i => t.todoIDsByUser[i] || [])).size)
          .reduce((a, b) => a + b, 0)
      },
      publicTasks: function () {
        const tasks = this.publishedTasks
        const materials = (((this.$root.catalog || []).find(x => x.name === 'Material') || {}).children || []).filter(x => !x.advanced && x.active)
        const materialTasks = materials.map(mat => this.materialToTask(mat))
        return tasks.concat(materialTasks)
      },
      materialIDToPathMap: function () {
        const materials = ((this.catalog || []).find(x => x.name === 'Material') || {}).children || []
        const pathIDs = {}
        materials.forEach(mat => {
          const newPath = encodeURIComponent(mat.name.toLowerCase().replace(/ /g, '-'))
          if (!pathIDs[newPath]) pathIDs[newPath] = []
          pathIDs[newPath].push(mat.uuid)
        })
        const result = {}
        Object.keys(pathIDs).forEach(path => {
          pathIDs[path].forEach((id, i) => {
            if (i === 0) result[pathIDs[path][i]] = path
            else result[pathIDs[path][i]] = `${path}-${i + 1}`
          })
        })
        return result
      },
      materialPathToIDMap: function () {
        const result = {}
        Object.keys(this.materialIDToPathMap).forEach(k => {
          result[this.materialIDToPathMap[k]] = k
        })
        return result
      },
      publicTaskIDToPathMap: function () {
        // Block off paths used by Lana
        const pathIDs = {}
        const routeBlacklist = [
          '404',
          'login',
          'dashboard',
          'tasks',
          'task',
          'chat',
          'scanner',
          'reader',
          'projects',
          'info',
          'materials',
          'submit',
          'counting',
          'playground',
          'landing',
          'inventory',
          'queue',
          'customers',
          'company',
          'organizations',
          'project-list',
          'flagged-messages',
          'staff',
          'eula',
          'privacypolicy',
          'termsandconditions',
        ]
        routeBlacklist.forEach(route => { pathIDs[route] = [null] })

        // Generate path for every published task
        this.publishedTasks.forEach(task => {
          const newPath = encodeURIComponent(this.taskName(task).toLowerCase().replace(/ /g, '-'))
          if (!pathIDs[newPath]) pathIDs[newPath] = []
          pathIDs[newPath].push(task.uuid)
        })

        // Handle path conflicts
        const result = {}
        Object.keys(pathIDs).forEach(path => {
          pathIDs[path].forEach((id, i) => {
            if (id) {
              if (i === 0) result[pathIDs[path][i]] = path
              else result[pathIDs[path][i]] = `${path}-${i + 1}`
            }
          })
        })
        return result
      },
      publicTaskPathToIDMap: function () {
        const result = {}
        Object.keys(this.publicTaskIDToPathMap).forEach(k => {
          result[this.publicTaskIDToPathMap[k]] = k
        })
        return result
      },
      recentAccountProject: function () {
        if (this.userProfile && this.userProfile.recentProjects) {
          return this.userProfile.recentProjects[this.userProfile.recentProjects.length - 1]
        } else return null
      },
      projectPreview: function () {
        return this.localRecentProject || this.recentAccountProject || this.localRecentProject
      },
      myPermissions: function () {
        return Array.from(new Set(this.myTeams.flatMap(x => x.permissions || [])))
      },
      listOrder: function () {
        return this.$root.management ? (this.$root.userProfile && this.$root.userProfile.prefs_showDrafts ? this.$root.management.listOrder : this.$root.management.listOrder.filter(x => x !== 5)) : null
      },
      locationView: {
        get: function () {
          if (this.userProfile && (this.userProfile.prefs_location === '' || this.allLocations.find(x => x.id === this.userProfile.prefs_location))) return this.userProfile.prefs_location
          else return this.allLocations[0] ? this.allLocations[0].id : ''
        },
        set: function (val) {
          if (this.userProfile) this.$set(this.userProfile, 'prefs_location', val)
        },
      },
      allLocations: function () {
        return this.enterprise.locations.filter(x => x.active) || [this.enterprise.defaultLocation]
      },
      locations: function () {
        const allMyLocations = Array.from(new Set(this.myTeams.flatMap(x => x.locations || [this.enterprise.defaultLocation]).filter(x => x)))
        return this.allLocations.filter(x => allMyLocations.includes(x.id))
      },
      locationViews: function () {
        return [{ id: '', name: 'Show All' }].concat(this.allLocations)
      },
      holiday: function () {
        if (this.$root.userProfile && this.$root.userProfile.prefs_disableHolidayExpiration > Date.now()) return null
        const day = new Date().getDate()
        const month = new Date().getMonth() + 1
        if (month === 12 && day <= 25) return 'christmas'
        else if ((month === 12 && day >= 31) || (month === 1 && day <= 1)) return 'newyears'
        else if (month === 7 && day <= 4) return 'july4'
        // else if (month === 4 && (day === 1 || day === 21) && this.$root.enterpriseID === 't9WXKqgdGpfQdxN54DKi') return 'aprilfools'
        // else if (month === 9 && day === 14) return 'leogreencard'
        else return null
      },
      debug: function () {
        return this.currentUser && this.userProfile && this.userProfile.prefs_debug
      },
      machineTags: function () {
        if (this.management && this.management.machineTags) return this.management.machineTags.filter(x => x.active !== false)
        else return []
      },
      myTeams: function () {
        return this.allTeams.filter(x => x.uuid === 'public' || x.members.includes(this.userID))
      },
      unreadNotifications: function () {
        return this.userNotifications.filter((n) => !n.read).length
      },
      testMode: function () {
        return this.currentUser && this.userProfile && this.userProfile.testMode
      },
      taskLists: function () {
        const lists = JSON.parse(JSON.stringify(this.enterprise.taskLists || []))
        if (!this.$root.configPublic.enableMaterialTasks) return lists

        const allTechs = this.$root.catalog?.find(x => x.name === 'Technology')?.children || []
        const techs = allTechs.filter(t => {
          if (t.advanced) return false
          if (!t.active) return false
          const materials = this.$root.catalog.find(x => x.name === 'Material').children
            .filter(c => !c.restrictions.Technology.length || c.restrictions.Technology.includes(t.name))
            .filter(c => c.active &&  !c.advanced)
          return !!materials.length
        })

        const techLists = techs.map(tech => ({ name: tech.displayName, uuid: tech.uuid }))
        const materialsList = lists.find(x => x.uuid === this.$root.configPublic.materialTasksList)
        if (materialsList) {
          if (!materialsList.megaMenuSubcategories) materialsList.megaMenuSubcategories = []
          if (!materialsList.megaMenuSubcategorySortOrders) materialsList.megaMenuSubcategorySortOrders = {}
          materialsList.megaMenuSubcategories.push(...techs.map(x => x.uuid))
          techs.forEach((tech, t) => { materialsList.megaMenuSubcategorySortOrders[tech.uuid] = t })
        }
        return lists.concat(techLists)
      },
      isAlumni: function () {
        if (this.$root.userToken?.claims?.admin) return true
        return this.claims.access >= 4
      },
      isStaff: function () {
        if (this.$root.userToken?.claims?.admin) return true
        return this.claims.access >= 5
      },
      isExecutive: function () {
        if (this.$root.userToken?.claims?.admin) return true
        return this.claims.access >= 8
      },
      isAdmin: function () {
        if (this.$root.userToken?.claims?.admin) return true
        return this.claims.access >= 9
      },
      staffAndAlumniList: function () {
        return Object.keys(this.staff).filter(x => this.staff[x].access >= 4).map(uid => ({ value: uid, text: this.lookup.users[uid].displayName || this.lookup.users[uid].fullName, icon: this.lookup.users[uid].profilePic, email: this.lookup.users[uid].email, fullName: this.lookup.users[uid].fullName || this.lookup.users[uid].displayName }))
      },
      staffList: function () {
        return Object.keys(this.staff).filter(x => this.staff[x].access >= 5).map(uid => ({ value: uid, text: this.lookup.users[uid].displayName || this.lookup.users[uid].fullName, icon: this.lookup.users[uid].profilePic, email: this.lookup.users[uid].email, fullName: this.lookup.users[uid].fullName || this.lookup.users[uid].displayName }))
      },
      staffAndTeamList: function () {
        const teams = this.$root.allTeams.map(x => ({ text: x.name, value: x.uuid, isTeam: true }))
        return this.$root.staffList.concat(teams)
      },
      catalog: function () {
        return this.production.catalog || []
      },
      catalogCompatibilityMap: function () {
        const map = {}
        this.catalog.forEach(optionA => {
          optionA.children.forEach(childA => {
            this.catalog.forEach(optionB => {
              optionB.children.forEach(childB => {
                // Determine compatibility between optionA/childA and optionB/childB
                const childARestrictions = (childA.restrictions || {})[optionB.name]
                const childBRestrictions = (childB.restrictions || {})[optionA.name]
                const childAAcceptsChildB = !childARestrictions || childARestrictions.includes(childB.name)
                const childBAcceptsChildA = !childBRestrictions || childBRestrictions.includes(childA.name)
                const compatible = childAAcceptsChildB && childBAcceptsChildA

                // Add to map
                if (!map[optionA.name]) map[optionA.name] = {}
                if (!map[optionA.name][childA.name]) map[optionA.name][childA.name] = {}
                if (!map[optionA.name][childA.name][optionB.name]) map[optionA.name][childA.name][optionB.name] = {}
                if (!map[optionA.name][childA.name][optionB.name][childB.name]) map[optionA.name][childA.name][optionB.name][childB.name] = {}

                if (!map[optionB.name]) map[optionB.name] = {}
                if (!map[optionB.name][childB.name]) map[optionB.name][childB.name] = {}
                if (!map[optionB.name][childB.name][optionA.name]) map[optionB.name][childB.name][optionA.name] = {}
                if (!map[optionB.name][childB.name][optionA.name][childA.name]) map[optionB.name][childB.name][optionA.name][childA.name] = {}

                map[optionA.name][childA.name][optionB.name][childB.name] = compatible
                map[optionB.name][childB.name][optionA.name][childA.name] = compatible
              })
            })
          })
        })
        return map
      },
      allMachines: function () {
        if (!this.management || !this.management.machines) return []
        const machines = this.management.machines
        machines.forEach(machineType => {
          for (let i = 0; i < machineType.instances.length; i++) {
            const uuid = machineType.instances[i].uuid
            if (this.allMachineInstancesByID[uuid]) {
              machineType.instances[i] = this.allMachineInstancesByID[uuid]
            }
          }
        })
        return machines
      },
      machines: function () {
        if (this.locationView) {
          return this.allMachines.map((machine, m) => {
            const newMachine = Object.assign({}, machine)
            newMachine.instances = this.allMachines[m].instances.filter(i => this.canShowLocation(i.location))
            return newMachine
          }).filter(x => x.instances.length)
        } else return this.allMachines
      },
      machineAvailability: function () {
        return this.production.machines
      },
      catalogByName: function () {
        const result = Object.assign({}, ...this.catalog.map(x => ({ [x.name]: Object.assign({}, x) })))
        this.catalog.forEach(o => {
          result[o.name].children = Object.assign({}, ...result[o.name].children.map(x => ({ [x.name]: x })))
        })
        return result
      },
    },
    watch: {
      machineWebsocketSubscriptionKey: {
        handler: function (val) {
          this.sendMachineStateSubscription()
        },
        immediate: true,
      },
      jobPreviewID: {
        handler: function (val) {
          if (this.jobPreviewListener) this.jobPreviewListener()
          this.jobPreviewData = null
          if (val) {
            this.jobPreviewListener = onSnapshot(
              doc(this.db, 'enterprises', this.enterpriseID, 'jobs', val),
              snap => {
                this.jobPreviewData = Object.assign({ id: snap.id }, snap.data())
              },
              err => {
                console.log('Error listening to job preview', err)
              },
            )
          }
        },
        immediate: true,
      },
      showMemory: {
        handler: function (val) {
          if (val) {
            this.memoryInterval = setInterval(() => {
              // const current = this.memoryHistory.length ? this.memoryHistory[this.memoryHistory.length - 1].totalJSHeapSize : 0
              const memoryUsage = window.performance.memory
              this.memoryHistory.push(memoryUsage)
              // const updated = memoryUsage.totalJSHeapSize
              // const diff = updated - current
              // if (diff) console.log('DIVISOR', diff / 8312)
              // console.log('diff', updated - current)
              if (this.memoryHistory.length > this.memorySamples) this.memoryHistory.shift()
            }, 100)
          } else {
            clearInterval(this.memoryInterval)
            this.memoryHistory = []
          }
        },
        immediate: true,
      },
      lightHeaderText: {
        handler: function (val) {
          if (Capacitor.isNativePlatform()) StatusBar.setStyle({ style: val ? Style.Dark : Style.Light })
        },
        immediate: true,
      },
      showInviteDialog: {
        handler: function (newVal, oldVal) {
          if (oldVal && !newVal) {
            this.acceptingStaffInvite = false
            this.$router.push('/')
          }
        },
        immediate: true,
      },
      viewableTasks: function (val) {
        this.totalReaderTasks = val.length
      },
      taskReaderTeams: {
        handler (val) {
          if (val && val.length) {
            if (this.visibleTaskListener) this.visibleTaskListener()
            this.visibleTaskListener = onSnapshot(
              query(
                collection(this.db, 'enterprises', this.enterpriseID, 'chats'),
                where('readerVisibility', 'array-contains-any', val),
                where('active', '==', true),
              ),
              snap => {
                let result = snap.docs.map(d => Object.assign({ uuid: d.id }, d.data()))
                if (this.configPublic.enableMaterialTasks) {
                  const materialTasks = (((this.catalog || []).find(x => x.name === 'Material') || {}).children || []).filter(x => !x.advanced && x.active).map(mat => this.materialToTask(mat))
                  result = result.concat(materialTasks)
                }
                this.viewableTasks = result
              },
              err => {
                console.log('Error listening to task reader teams', err)
              },
            )
          }
        },
        immediate: true,
      },
      $route: function () {
        this.organizationPreviewOpen = false
      },
      enterpriseOverride: function (val) {
        if (val) window.localStorage.setItem('enterpriseOverride', val)
        else window.localStorage.removeItem('enterpriseOverride')
        const newEnterpriseID = val === null ? this.domainEnterprise.enterpriseID : val
        if (newEnterpriseID !== this.enterpriseID) window.location.reload()
      },
      staffEnterpriseIDs: {
        handler: function (val) {
          this.staffEnterprisesLoaded = !!this.userToken
          if (this.staffEnterprisesLoaded) {

            // Choose a new enterprise override if on mobile
            if (Capacitor.isNative && !this.enterpriseOverride && val.length) {
              if (val.length === 1) this.enterpriseOverride = val[0]
              else this.forceEnterpriseOverrideSelection = true
            }
          }

          Object.keys(this.staffEnterpriseListeners).forEach(k => {
            if (!val.includes(k)) {
              this.staffEnterpriseListeners[k]()
              delete this.staffEnterpriseListeners[k]
            }
          })

          Object.keys(this.staffEnterpriseNotificationListeners).forEach(k => {
            if (!val.includes(k)) {
              this.staffEnterpriseNotificationListeners[k]()
              delete this.staffEnterpriseNotificationListeners[k]
            }
          })

          val.forEach(id => {
            if (!this.staffEnterpriseListeners[id]) {
              this.$set(this.staffEnterpriseListeners, id, onSnapshot(
                doc(this.db, 'enterprises', id),
                snap => {
                  if (!snap.exists()) {
                    this.$delete(this.staffEnterpriseNames, id)
                    this.$delete(this.staffEnterpriseURLs, id)
                    return
                  }
                  this.$set(this.staffEnterpriseNames, id, snap.data().name && snap.data().name !== 'formfactories' ? snap.data().name : snap.data().description)
                  this.$set(this.staffEnterpriseURLs, id, snap.data().domains[0].domain)
                },
                err => {
                  console.log('Error listening to staff enterprises', err)
                },
              ))
            }

            if (id !== this.enterpriseID && !this.staffEnterpriseNotificationListeners[id]) {
              this.$set(this.staffEnterpriseNotificationListeners, id, onSnapshot(
                query(
                  collection(this.db, 'enterprises', id, 'users', this.$root.userID, 'notifications'),
                  where('read', '==', false),
                  limit(10)
                ),
                snap => {
                  this.$set(this.staffEnterpriseUnreadNotificationCount, id, snap.docs.length)
                },
                err => {
                  console.log('Error listening to staff enterprise notifications', err)
                },
              ))
            }
          })
        },
        immediate: true,
      },
      enterpriseID: {
        handler: function (val, oldVal) {
          window.enterpriseID = val
          if (oldVal && val && oldVal !== val) {
            // Switch enterprises
            console.log('switching enterprise to', val, 'from', oldVal)
          }
        },
        immediate: true,
      },
      enterprise: {
        handler: function (val) {
          window.enterprise = val
        },
        immediate: true,
      },
      cartOpen: function (val) {
        if (val && this.showProjectPreview) this.showProjectPreview = false
      },
      algoliaCredentials: {
        handler: function (val) {
          // Create algolia client and indices
          if (val.applicationID && val.searchKey) {
            this.algoliaClient = algoliasearch(val.applicationID, val.searchKey)
            this.searchProjectIndex = app.algoliaClient.initIndex('projects')
            this.searchUserIndex = app.algoliaClient.initIndex('users')
            this.searchOrganizationIndex = app.algoliaClient.initIndex('organizations')
            this.searchChatIndex = app.algoliaClient.initIndex('chats')
            this.searchJobIndex = app.algoliaClient.initIndex('jobs')
          } else {
            this.algoliaClient = null
            this.searchProjectIndex = null
            this.searchUserIndex = null
            this.searchOrganizationIndex = null
            this.searchChatIndex = null
            this.searchJobIndex = null
          }
        },
        immediate: true
      },
      lightTheme: {
        handler: function (val) {
          this.$vuetify.theme.themes.light.primary = val.color
        },
        immediate: true,
      },
      darkTheme: {
        handler: function (val) {
          this.$vuetify.theme.themes.dark.primary = val.color
        },
        immediate: true,
      },
      darkMode: {
        handler: function (val) {
          this.$vuetify.theme.dark = val
        },
        immediate: true,
      },
      brandProperties: {
        handler: function (val) {
          if (!val) return
          Object.keys(val).forEach(key => {
            document.documentElement.style.setProperty(key, val[key])
          })
        },
        immediate: true,
      },
      showProjectPreview: function (val) {
        this.validateProjectPreview()
        if (this.cartOpen) this.cartOpen = false
      },
      projectPreview: {
        immediate: true,
        handler: function (val) {
          if (!val) {
            const cur = localStorage.getItem('recentProject')
            if (cur) this.localRecentProject = cur
            else {
              this.$root.callFunction('createProject', { enterprise: this.$root.enterpriseID })
                .then(response => {
                  const uuid = response.data
                  localStorage.setItem('recentProject', uuid)
                  this.localRecentProject = uuid
                })
            }
          } else this.validateProjectPreview()
        },
      },
      anonymousUserName: function (val) {
        this.saveAnonymousUserInfo()
      },
      anonymousUserEmail: function (val) {
        this.saveAnonymousUserInfo()
      },
      autoEmailConfirmationQueue: function (val) {
        if (val.length && !this.autoEmailConfirmation) this.autoEmailConfirmation = this.autoEmailConfirmationQueue.shift()
        this.showAutoEmailConfirmation = !!this.autoEmailConfirmation
      },
      autoEmailConfirmation: function (val) {
        if (!val && this.autoEmailConfirmationQueue) {
          const comp = this
          setTimeout(function () {
            if (!comp.autoEmailConfirmation) comp.autoEmailConfirmation = comp.autoEmailConfirmationQueue.shift()
          }, 800)
        }
        this.showAutoEmailConfirmation = !!this.autoEmailConfirmation
      },
      unreadNotifications: {
        immediate: true,
        handler: function (val) {
          if (Capacitor.isNativePlatform()) Badge.set({ count: val })
        },
      },
      showNotifications: function (newVal, oldVal) { app.updateNotifications() },
      notificationPage: function (newVal, oldVal) { app.listenToNotifications() },
      userProfile: {
        handler (val) {
          if (app.currentUser) {
            if (!app.state.skipChange.userProfile) {
              app.state.skipChange.userProfile = true
              val.dateModified = Date.now()
              //still a bandaid but a better bandaid
              if(!val.dashboardPresets) { 
                getDoc(doc(app.db, 'enterprises', app.enterpriseID, 'users', this.currentUser.uid)).then(doc => {
                  if(doc.exists()) {
                    if(doc.data().dashboardPresets) val.dashboardPresets = doc.data().dashboardPresets
                  }
                })
              }
              //end bandaid
              updateDoc(doc(app.db, 'enterprises', app.enterpriseID, 'users', this.currentUser.uid), val).catch(err => console.log('Error updating user profile', err))
              this.state.uploadingProfilePic = false
            } else app.state.skipChange.userProfile = false

            if (val.prefs_brand && this.isStaff) setTheme(val.prefs_brand)
          } else setTheme()
        },
        deep: true,
      },
    },
    created: function () {
      // Prevent leaving when jobs are uploading
      const app = this
      window.addEventListener('beforeunload', function (e) {
        if (app.activeUploads > 0) {
          e.preventDefault()
          e.returnValue = ''
        }
      })

      // Start listening to machine state server
      this.connectToMachineStateServer()

      // Check if we have an invite code
      this.staffInviteID = new URLSearchParams(window.location.search).get('invite')

      // Capture paste events - useful for putting files into chats
      document.addEventListener('paste', e => app.$emit('paste', e))

      this.$on('keydown', this.keyDown)
      this.$on('keyup', this.keyUp)

      // Get enterprise updates
      onSnapshot(
        doc(this.db, 'enterprises', this.enterpriseID),
        snap => { if (snap.data()) this.enterpriseRaw = snap.data() },
        err => console.log('Error listening to enterprise snapshot', err),
      )
      onSnapshot(
        doc(this.db, 'configuration', 'public'),
        snap => { if (snap.data())this.publicConfiguration = snap.data() },
        err => console.log('Error listening to public configuration', err),
      )
      onSnapshot(
        doc(this.db, 'config', 'plans'),
        snap => { if (snap.data()) this.allTiers = snap.data().tiers },
        err => console.log('Error listening to plans', err),
      )

      // Get default enterprise
      getDomainEnterprise().then(res => {
        this.domainEnterprise = { text: `Default (${res.enterprise.name})`, value: null, enterpriseID: res.id }
      })

      // Detect system dark theme preference
      this.darkModePreference = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
      window.matchMedia('(prefers-color-scheme: dark)')
        .addEventListener('change', e => { this.darkModePreference = e.matches })

      // Refresh current time every second
      this.currentTimeInterval = setInterval(function () { app.currentTime = Date.now() }, 1000)
      this.currentTimeMinutesInterval = setInterval(function () { app.currentTimeMinutes = Date.now() }, 60000)

      // Handle mobile push notifications
      if (Capacitor.isNativePlatform()) {
        // On registration of notification permission, register the token with the user
        PushNotifications.addListener(
          'registration',
          function (token) {
            if (!app.currentUser || !token.value) return
            app.notificationToken = token.value
            setDoc(
              doc(app.db, 'users', app.currentUser.uid, 'devices', app.notificationToken),
              { active: true, dateModified: Date.now() },
              { merge: true },
            ).catch(err => console.log('Error registering notification token', err))
          }
        )

        // Method called when tapping on a notification
        PushNotifications.addListener(
          'pushNotificationActionPerformed',
          function (action) {
            const notification = JSON.parse(action.notification.data.json)
            app.openMobileNotification(notification)
          },
        )
      }
    },
    mounted: function () {
      this.listenToTeams()

      // Restore any saved user info
      if (localStorage.getItem('anonymousUserName') !== null) this.anonymousUserName = localStorage.getItem('anonymousUserName')
      if (localStorage.getItem('anonymousUserEmail') !== null) this.anonymousUserEmail = localStorage.getItem('anonymousUserEmail')

      // Listen to catalog and sales options
      const app = this
      onSnapshot(
        doc(this.db, 'enterprises', this.enterpriseID, 'config', 'production'),
        docSnap => {
          if (docSnap.exists()) this.production = docSnap.data()
        },
        err => console.log('Error in catalog snapshot listening:', err),
      )

      // Listen to general config settings
      onSnapshot(
        doc(this.db, 'enterprises', this.enterpriseID, 'config', 'public'),
        docSnap => {
          if (docSnap.exists()) this.configPublic = docSnap.data()
        },
        err => console.log('Error in public config snapshot listening:', err),
      )

      // Listen to selling points collection
      onSnapshot(
        query(
          collection(this.db, 'enterprises', this.enterpriseID, 'sellingPoints'),
          where('active', '==', true),
        ),
        snap => {
          const points = {}
          snap.docs.forEach(d => { points[d.id] = d.data() })
          this.sellingPoints = points
        },
        err => console.log('Error in selling points snapshot listening:', err),
      )

      // Listen to sales options collection
      onSnapshot(
        doc(this.db, 'enterprises', this.enterpriseID, 'reference', 'salesOptions'),
        docSnap => {
          if (docSnap.exists()) this.salesOptions = docSnap.data()
        },
        err => console.log('Error in salesOptions snapshot listening:', err),
      )

      // Get all published tasks
      onSnapshot(
        query(
          collection(this.$root.db, 'enterprises', this.$root.enterpriseID, 'chats'),
          where('published', '==', true),
          where('active', '==', true),
        ),
        snap => {
          this.publishedTasks = snap.docs.map(d => Object.assign(d.data(), { uuid: d.id }))
        },
        err => console.log('Error listening to published tasks snapshot', err),
      )

      // Get all sales tax rates
      onSnapshot(
        collection(this.$root.db, 'enterprises', this.$root.enterpriseID, 'customTaxes'),
        snap => {
          this.taxRates = snap.docs.map(d => Object.assign(d.data(), { id: d.id })).sort((a, b) => a.order - b.order)
        },
        err => console.log('Error listening to tax rate snapshot', err),
      )

      // Check if any notification needs to be opened
      if (localStorage.getItem('notificationToOpen')) {
        this.openMobileNotification(JSON.parse(localStorage.getItem('notificationToOpen')))
        localStorage.removeItem('notificationToOpen')
      }
    },
    methods: {
      connectToMachineStateServer: function () {
        this.machineStateServerSocket = new WebSocket('wss://gateway-ingest.formfactories.com')
        this.machineStateServerSocket.onopen = () => {
          //console.log('Connected to machine state server')
          this.sendMachineStateSubscription()
        }
        this.machineStateServerSocket.onclose = x => {
          //console.log('Disconnected from machine state server', x)
          setTimeout(this.connectToMachineStateServer, 5000)
        }
        this.machineStateServerSocket.onerror = err => {
          //console.log('Error connecting to machine state server:', err)
        }
        this.machineStateServerSocket.onmessage = event => {
          const data = JSON.parse(event.data)
          if (data.type === 'error') console.log('Machine state server error:', data.message)
          else {
            if (data.type === 'state' && data.data) {
              Object.keys(this.machineWebsocketsSubscriptions).forEach(subID => {
                const id = this.machineWebsocketsSubscriptions[subID].id
                if (data.data[id]) this.machineWebsocketsSubscriptions[subID].callback(data.data[id])
              })
            }
          }
        }
      },
      sendMachineStateSubscription: function () {
        if (this.machineStateServerSocket?.readyState === WebSocket.OPEN) {
          this.machineStateServerSocket.send(JSON.stringify({
            type: 'subscribe',
            data: this.machineWebsocketSubscriptionKey,
            enterprise: this.enterpriseID,
          }))
        }
      },
      subscribeToMachine: function (machineID, scope = ['printerState'], callback) {
        const subID = this.generateUUID()
        this.$set(this.machineWebsocketsSubscriptions, subID, {
          id: machineID,
          scope,
          callback,
        })
        return () => this.$delete(this.machineWebsocketsSubscriptions, subID)
      },
      startRecordingFailureEvent: function (machineID, jobID, projectID) {
        this.queuedFailureEvent = { machineID, jobID, projectID }
        this.openMachinePanel(machineID)
      },
      lightenedThemeColor: function (amount) {
        if (amount < 0) return this.darkenedThemeColor(-amount)
        return this.blendColors(this.theme.color, '#FFFFFF', amount)
      },
      darkenedThemeColor: function (amount) {
        if (amount < 0) return this.lightenedThemeColor(-amount)
        return this.blendColors(this.theme.color, '#000000', amount)
      },
      previewProject: function (projectID) {
        if (projectID) {
          this.projectPreviewID = projectID
          this.projectPreviewOpen = true
        } else {
          this.projectPreviewOpen = false
        }
      },
      previewJob: function (jobID) {
        if (jobID) {
          this.jobPreviewID = jobID
          this.jobPreviewOpen = true
        } else {
          this.jobPreviewOpen = false
        }
      },
      getStripeTaxCodes: function () {
        if (this.cachedStripeTaxCodes && this.cachedStripeTaxCodesRetrieved > Date.now() - 1000 * 60 * 60 * 24) return Promise.resolve(this.cachedStripeTaxCodes)
        else if (this.cachedStripeTaxCodesPromise) return this.cachedStripeTaxCodesPromise
        else {
          this.cachedStripeTaxCodesPromise = this.callFunction('listTaxCodes', { enterprise: this.$root.enterpriseID })
            .then(response => {
              this.cachedStripeTaxCodes = response.data
              this.cachedStripeTaxCodesRetrieved = Date.now()
              this.cachedStripeTaxCodesPromise = null
              return this.cachedStripeTaxCodes
            })
          return this.cachedStripeTaxCodesPromise
        }
      },
      optionsToShow: function (model, hideBaseOptions = false) {
        const results = []

        // Match all options that are currently selected
        const optionPicks = []
        const optionPicksData = []
        this.$root.catalog.forEach((option, o) => {
          const match = this.matchOptionFromModel(option.name, model)
          optionPicks.push(match)
          optionPicksData.push(match ? this.$root.catalogByName[option.name].children[match] : null)
        })

        // Add all options that are compatible with the current selections
        this.$root.catalog.forEach((option, o) => {
          const compatibleChildren = []
          option.children.forEach(child => {
            let compatible = true

            // Check compatibility with previous options
            for (let i = 0; i < o; i++) {
              const previousOption = this.$root.catalog[i]
              const previousOptionPick = optionPicks[i]
              const previousOptionPickData = optionPicksData[i]

              if (!previousOptionPick || !previousOptionPickData) continue
              if (!this.$root.catalogCompatibilityMap[option.name][child.name][previousOption.name][previousOptionPickData.name]) {
                compatible = false
                break
              }
            }

            if (compatible) compatibleChildren.push(child.name)
          })

          if (compatibleChildren.length) {
            results.push({ ...option, children: compatibleChildren })
          }
        })
        
        return hideBaseOptions ? results.filter(x => !['Technology', 'Material', 'Color'].includes(x.name)) : results
      },
      keyUp: function (e) {
        this.$emit('key-up-code', e.code)
        if (e.code === 'Space') this.spaceUnpressed(e)
      },
      keyDown: function (e) {
        this.$emit('key-down-code', e.code)
        if (e.code === 'Space') this.spacePressed(e)
      },
      spaceUnpressed: function (e) {
        // Ignore if a field is being edited
        if (document.activeElement && document.activeElement.id !== 'search-query' && (['INPUT', 'TEXTAREA'].includes(document.activeElement.tagName) || document.activeElement.contentEditable === 'true')) return

        // Close project preview if space was pressed over a second ago
        this.spaceDown = false
        if (this.projectPreviewOpen && this.spaceHoldDeadline && this.spaceHoldDeadline < Date.now()) {
          e.preventDefault()
          this.spaceHoldDeadline = null
          this.projectPreviewOpen = false
        }
      },
      spacePressed: function (e) {
        // Ignore if a field is being edited
        if (document.activeElement && document.activeElement.id !== 'search-query' && (['INPUT', 'TEXTAREA'].includes(document.activeElement.tagName) || document.activeElement.contentEditable === 'true')) return

        // Prevent scrolling down page if holding space to preview project
        if (this.spaceDown) {
          if (this.projectPreviewOpen) e.preventDefault()
          return
        } else this.spaceDown = true

        if (this.projectPreviewOpen) {
          e.preventDefault()
          this.spaceHoldDeadline = null
          this.projectPreviewOpen = false
          return
        }

        this.spaceHoldDeadline = Date.now() + 1000
        this.$emit('space-pressed', e)
      },
      openMachinePanel(machineID) {
        this.machinePanelID = machineID
        this.machinePanelOpen = true
      },
      // Returns a hash of the models color, including color changes
      modelColorName: function (model, modelData) {
        if (!model || !modelData || !model.requestedSettings) return ''
        return [
            model.requestedSettings.Color,
            ...(model.colorChanges || []).filter(x => x.color).map(cc => (cc.height.replace('.', '-') || '') + cc.color)
        ].join('_')
      },
      modelThumbnailDetails: function (modelDataOrProject = {}, model) {
        let modelData = modelDataOrProject || {}
        if (modelDataOrProject.modelData) modelData = modelDataOrProject.modelData[model.uuid] || {}

        const colorThumbnails = modelData.colorThumbnails || {}
        const v3Thumbnail = colorThumbnails[this.modelColorName(model, modelData)]
        const v3DefaultThumbnail = colorThumbnails.Default || ''
        if (v3Thumbnail) return { loading: false, url: v3Thumbnail }
        if (Object.keys(colorThumbnails).length) return { loading: true, url: v3DefaultThumbnail }
        if (v3DefaultThumbnail) return { loading: false, url: v3DefaultThumbnail }

        const v2Thumbnail = (modelData.thumbnails || [])[0] || ''
        if (v2Thumbnail) return { loading: false, url: v2Thumbnail }

        const v1Thumbnail = (model.thumbnails || [])[0] || ''
        if (v1Thumbnail) return { loading: false, url: v1Thumbnail }

        const modelJobThumbnail = modelData?.jobAnalysis?.thumbnail || ''
        if (modelJobThumbnail) return { loading: true, url: modelJobThumbnail }

        return { loading: true, url: '' }
      },
      designThumbnailDetails: function (design) {
        if (!design) return { loading: false, url: '' }
        const configurationColors = (design.configurations || []).map(c => c.settings.Color).filter(c => c)
        const validConfigurationColors = configurationColors.filter(c => design.colorThumbnails && design.colorThumbnails[c])
        let url = validConfigurationColors.length ? design.colorThumbnails[configurationColors[0]] : design.thumbnail
        let loading = configurationColors.length && (!design.colorThumbnails || !design.colorThumbnails[configurationColors[0]])
        return { loading, url }
      },
      modelThumbnail: function (modelDataOrProject, model) {
        return this.modelThumbnailDetails(modelDataOrProject, model).url
      },
      roughSizeOfObject: function ( object ) {
        var objectList = [];
        var stack = [ object ];
        var bytes = 0;
        while ( stack.length ) {
            var value = stack.pop();
    
            if ( typeof value === 'boolean' ) {
                bytes += 4;
            }
            else if ( typeof value === 'string' ) {
                bytes += value.length * 2;
            }
            else if ( typeof value === 'number' ) {
                bytes += 8;
            }
            else if
            (
                typeof value === 'object'
                && objectList.indexOf( value ) === -1
            )
            {
                objectList.push( value );
    
                for( var i in value ) {
                    if (['cssRules'].includes(i)) return
                    stack.push( value[ i ] );
                }
            }
        }
        return bytes;
      },
      resetToken: function () {
        this.currentUser.getIdToken(true)
          .then(() => this.currentUser ? auth.currentUser.getIdTokenResult() : Promise.resolve(null))
          .then(token => {
            this.userToken = token
            window.userToken = token
            return updateDoc(doc(db, 'enterprises', this.enterpriseID, 'users', this.currentUser.uid), { tokenExpired: false }).catch(err => console.log('Error resetting token', err))
          })
      },
      uploadJob: function (id, file) {
        // Start uploading
        const storageRef = ref(this.storage, `jobs/${id}/${file.name}`)
        const uploadTask = uploadBytesResumable(storageRef, file)

        // Upload and keep track of status
        const app = this
        this.activeUploads++
        uploadTask.on('state_changed', function (snapshot) {
          updateDoc(doc(app.db, 'enterprises', app.enterpriseID, 'jobs', id), {
            uploadProgress: (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
            dateModified: Date.now(),
            lastUpdatedBy: app.userID,
          })
            .catch(error => console.log('Error updating job upload progress', error))
        }, function (error) {
          console.log('Error uploading job', file.name, error)
          updateDoc(doc(app.db, 'enterprises', app.enterpriseID, 'jobs', id), {
            uploadProgress: deleteField(),
            dateModified: Date.now(),
            lastUpdatedBy: app.userID,
          })
            .catch(error => console.log('Error updating job upload progress', error))
          app.activeUploads--
        }, function () {
          // Get link to file
          updateDoc(doc(app.db, 'enterprises', app.enterpriseID, 'jobs', id), {
            uploadProgress: 100,
            dateModified: Date.now(),
            lastUpdatedBy: app.userID,
          })
            .catch(error => console.log('Error updating job upload progress', error))
          getDownloadURL(storageRef)
            .then(url => {
              return updateDoc(doc(app.db, 'enterprises', app.enterpriseID, 'jobs', id), {
                url,
                dateModified: Date.now(),
                lastUpdatedBy: app.userID,
              })
            })
            .then(() => {
              app.activeUploads--
            })
            .catch(error => {
              console.log('Error getting link to job url for', file.name, error)
              app.activeUploads--
            })
        })
      },
      showError: function (error, type = 'error') {
        this.errors.push({
          message: error,
          type,
          time: Date.now(),
          id: this.generateUUID(),
        })
      },
      startWorkerTask: function (type, data) {
        return new Promise(resolve => {
          const id = this.generateUUID()
          this.workerTasks[id] = resolve
          this.worker.postMessage({ id, type, data })
        })
      },
      // Setup worker for some async tasks
      setupWorker: function () {
        if (window.Worker) {
          this.worker = new Worker('/worker.js')
          this.worker.onmessage = (e) => {
            if (this.workerTasks[e.data.id]) {
              this.workerTasks[e.data.id](e.data.data)
              delete this.workerTasks[e.data.id]
            }
          }
        }
      },
      productPrice: function (product, quantity = 1, variantID) {
        // Get pricing model
        let item = product
        const variant = product.variantData[variantID || product.variants[0]]
        if (variant.customPricing) item = variant

        // Get best applicable price
        const availablePrices = item.prices.filter(x => x.price && x.minQuantity <= quantity).map(x => x.price)
        return Math.min(...availablePrices)
      },
      blendColors: function (colorA, colorB, amount) {
        if (amount > 1) amount = 1
        if (typeof colorA === 'string') {
          const [rA, gA, bA] = colorA.match(/\w\w/g).map((c) => parseInt(c, 16))
          const [rB, gB, bB] = colorB.match(/\w\w/g).map((c) => parseInt(c, 16))
          const r = Math.round(rA + (rB - rA) * amount).toString(16).padStart(2, '0')
          const g = Math.round(gA + (gB - gA) * amount).toString(16).padStart(2, '0')
          const b = Math.round(bA + (bB - bA) * amount).toString(16).padStart(2, '0')
          return `#${r}${g}${b}`
        } else {
          const [rA, gA, bA] = colorA
          const [rB, gB, bB] = colorB
          const r = Math.round(rA + (rB - rA) * amount)
          const g = Math.round(gA + (gB - gA) * amount)
          const b = Math.round(bA + (bB - bA) * amount)
          return [r, g, b]
        }
      },
      callFunction: function (method, data) {
        return httpsCallable(this.functions, method)(data)
      },
      deleteAccount: function () {
        if (auth.currentUser) {
          return deleteUser(auth.currentUser)
            .catch(error => {
              console.log('Error deleting account:', error)
              throw new Error('Error deleting account, please contact us for assistance.')
            })
        } else return Promise.reject(new Error('No user is logged in'))
      },
      saveRecentProject: function (uuid) {
        // Save to recent projects
        if (this.userProfile) {
          const maxProjects = 20
          if (!this.userProfile.recentProjects) this.$set(this.userProfile, 'recentProjects', [])
          if (this.userProfile.recentProjects.includes(uuid)) this.userProfile.recentProjects = this.userProfile.recentProjects.filter(x => x !== uuid)
          this.userProfile.recentProjects.push(uuid)
          if (this.userProfile.recentProjects.length > maxProjects) this.userProfile.recentProjects = this.userProfile.recentProjects.slice(this.userProfile.recentProjects.length - maxProjects)
        }
      },
      validateProjectPreview: function () {
        if (this.projectPreview && !this.isStaff) {
          getDoc(doc(this.$root.db, 'enterprises', this.enterpriseID, 'projects', this.projectPreview)).then(docSnap => {
            if (!docSnap.data() ||docSnap.data().checkout || docSnap.data().status === 0 || docSnap.data().status === 3) {
              this.$root.callFunction('createProject', { enterprise: this.$root.enterpriseID })
                .then(response => {
                  const uuid = response.data
                  localStorage.setItem('recentProject', uuid)
                  this.localRecentProject = uuid
                  this.saveRecentProject(uuid)
                })
            }
          })
        }
      },
      cssValidNumber: function (val) {
        if (val === null || val === '' || val === undefined) return ''
        if (isNaN(+val)) return val
        else return +val + 'px'
      },
      materialToTask: function (material) {
        if (material) {
          const CREATOR = 'P1wkQjM9JHPNQcBk3VkVNAzBNSn1'
          const matTechnologies = (((this.$root.catalog || []).find(x => x.name === 'Technology') || {}).children || []).filter(x => material.restrictions.Technology.includes(x.name))
          const task = {
            readAccess: ['public'],
            dateModified: Date.now(),
            taskElements: [
              'headerGroup',
              'descriptionGroup',
              'advantagesLabel',
              'advantageGroup',
              'helpLabel',
              'compareLabel',
              'compareTable',
              'guideLabel',
              'materialGuideLink',
            ],
            taskElementData: {
              maximumSizeLabel: {
                type: 'h3',
                text: '<font color="#2196f3">maximum size</font>',
              },
              availableColorsLabel: {
                text: 'available colors',
                type: 'h3',
              },
              longDescription: {
                type: 'text',
                marginTop: 2,
                text: material.longDescription,
              },
              resolutionLabel: {
                text: '<font color="#2196f3">resolution</font>',
                type: 'h3',
              },
              headerGroup: {
                type: 'group',
                horizontal: true,
                taskElements: [
                  'headerImage',
                  'headerDetailsGroup',
                ],
                text: '',
              },
              descriptionAddon: {
                type: 'text',
                text: material.descriptionAddon,
              },
              materialSellingPoints: {
                text: '',
                type: 'component',
                component: 'material-selling-points',
                material: material.uuid,
              },
              technology: {
                text: '<b><font color="#555">Technology: ' + matTechnologies.map(tech => tech.task && this.$root.publicTaskIDToPathMap[tech.task] ? '<a href="/' + this.$root.publicTaskIDToPathMap[tech.task] + '">' + tech.displayName + '</a>' : tech.displayName).join(', ') + '</font></b>',
                type: 'text',
                marginTop: -2,
              },
              allStatsGroup: {
                type: 'group',
                horizontal: true,
                taskElements: [
                  'propertiesGroup',
                  'statsGroup',
                ],
              },
              detailsGroup: {
                taskElements: [
                  'colors',
                  'allStatsGroup',
                ],
                text: '',
                type: 'group',
              },
              compareTable: {
                type: 'component',
                component: 'material-chart',
                material: material.uuid,
                text: '',
              },
              advantageGroup: {
                type: 'group',
                card: true,
                taskElements: [],
                strip: false,
                horizontal: true,
                text: '',
              },
              headerImage: {
                text: '',
                type: 'text',
                attachments: [
                  {
                    height: 250,
                    name: 'Image-empty-state.jpg',
                    url: material.images && material.images.length ? material.images[0].url : material.image,
                    uuid: 'header-image-attachment',
                    uploaded: true,
                  },
                ],
              },
              videoHighlight: {
                text: '',
                type: 'text',
                attachments: material.video ? [
                  {
                    height: 250,
                    name: `${material.displayName} Showcase${material.video.toLowerCase().includes('.png') || material.video.toLowerCase().includes('.jpg') || material.video.toLowerCase().includes('.jpeg') ? '.jpg' : ''}`,
                    url: material.video,
                    external: !(material.video.toLowerCase().includes('.png') || material.video.toLowerCase().includes('.jpg') || material.video.toLowerCase().includes('.jpeg')),
                    uuid: 'youtube-video',
                    uploaded: true,
                  },
                ] : [],
              },
              datasheet: {
                text: material.datasheet ? `<a target="_blank" href="${material.datasheet}"><i class="mr-2 fas fa-file-alt"></i>View Data Sheet</a>` : '',
                type: 'h3',
              },
              resolutionGroup: {
                text: '',
                type: 'group',
                taskElements: [
                  'resolutionLabel',
                  'resolutionText',
                ],
              },
              colors: {
                text: '<font color="#2196f3">available colors</font>',
                colors: material.restrictions && material.restrictions.Color ? (material.restrictions.Color.map(c => this.$root.catalog.find(x => x.name === 'Color').children.find(x => x.name === c)).filter(color => color && color.active && (this.$root.isStaff || !color.advanced))) : [],
                type: 'h3',
                marginBottom: 4,
                marginTop: 0,
                marginLeft: 4,
                marginRight: 4,
              },
              materialGuideLink: {
                type: 'h1',
                marginBottom: 16,
                marginTop: 0,
                text: '<a href="/material-guide"><div style="text-align: right;"><font color="#2196f3">material guide &gt;</font></div></a>',
              },
              resolutionText: {
                text: material.resolution,
                type: 'text',
              },
              statsGroup: {
                text: '',
                type: 'group',
                marginTop: 8,
                marginLeft: 12,
                marginRight: 4,
                taskElements: [
                  'buildVolumeGroup',
                  'resolutionGroup',
                  'datasheet',
                ],
              },
              headerDetailsGroup: {
                type: 'group',
                text: '',
                justifyVertical: 'center',
                taskElements: [
                  'headerTitle',
                  'technology',
                  'longDescription',
                  'materialSellingPoints',
                ],
              },
              helpLabel: {
                type: 'h1',
                text: '<font color="#2196f3">need help choosing a material?</font>',
              },
              compareLabel: {
                type: 'h2',
                text: '<font color="#2196f3">compare with other ' + matTechnologies.map(x => (x.longName || '').split(' ').map(s => s === s.toUpperCase() ? s : s.toLowerCase()).join(' ')).join(', ') + '</font>',
              },
              headerTitle: {
                text: `<font color="#2196f3">${material.displayName}</font>`,
                type: 'h1',
                marginTop: 0,
              },
              buildVolumeText: {
                text: `${material.maxBuildSize}&nbsp;<br>${material.maxBuildSizeInches}&nbsp;<br>`,
                type: 'text',
              },
              propertiesGroup: {
                taskElements: ['propertiesLabel'],
                text: '',
                type: 'group',
              },
              propertiesLabel: {
                text: '<font color="#2196f3">properties</font>',
                type: 'h3',
                marginTop: 4,
                marginBottom: 2,
              },
              buildVolumeGroup: {
                taskElements: [
                  'maximumSizeLabel',
                  'buildVolumeText',
                ],
                text: '',
                type: 'group',
              },
              guideLabel: {
                text: '<div style="text-align: right;"><font color="#2196f3">or browse by use case in our&nbsp;</font></div>',
                type: 'h2',
                marginTop: 12,
              },
              descriptionGroup: {
                taskElements: [
                  'descriptions',
                  'detailsGroup',
                ],
                horizontal: true,
                strip: false,
                backgroundColor: '#0001',
                type: 'group',
                text: '',
              },
              advantagesLabel: {
                type: 'p',
                text: '',
              },
              descriptions: {
                type: 'group',
                taskElements: [
                  'descriptionAddon',
                  'videoHighlight',
                ],
                text: '',
              },
            },
            lastUpdatedBy: Date.now(),
            material: true,
            active: true,
            writeAccess: ['staff'],
            type: 'task',
            taggedUsers: [],
            list: (matTechnologies[0] || {}).uuid || 'd4d42e4d-1fa0-4252-af8e-8c84b7bc4ce7',
            created: Date.now(),
            members: [CREATOR],
            readerVisibility: ['public'],
            createdBy: CREATOR,
            uuid: material.uuid,
          }

          // Add gallery if there is one
          if (material.images && material.images.length > 1) {
            if (material.images.length <= 3) {
              task.taskElementData.galleryShowcase = {
                text: '',
                type: 'group',
                height: '500px',
                horizontal: true,
                taskElements: material.images.slice(1).map((img, i) => 'imageShowcase' + i),
              }
              material.images.slice(1).forEach((img, i) => {
                task.taskElementData['imageShowcase' + i] = {
                  text: '',
                  type: 'group',
                  taskElements: ['imageShowcaseText' + i],
                  backgroundFile: img.url,
                  backgroundContain: true,
                }
                task.taskElementData['imageShowcaseText' + i] = {
                  text: '',
                  type: 'text',
                  marginBottom: 80,
                }
              })
              task.taskElements.splice(4, 0, 'galleryShowcase')
            } else {
              task.taskElementData.galleryGroup = {
                taskElements: [
                  'galleryCollage',
                ],
                type: 'group',
                backgroundColor: '#0001',
                text: '',
              }
              task.taskElementData.galleryCollage = {
                text: '',
                type: 'p',
                attachmentLayout: 'collage',
                attachments: material.images.slice(1).map((img, i) => Object.assign({ uuid: i, height: 400 }, img)),
              }
              task.taskElements.splice(4, 0, 'galleryGroup')
            }
          }

          // Replace material names with respective links
          const materials = (((this.catalog || []).find(x => x.name === 'Material') || {}).children || []).filter(m => !m.advanced && m.active && m.uuid !== material.uuid)
          Object.values(task.taskElementData).forEach(e => {
            materials.forEach(mat => {
              const link = `<a href="/materials/${this.materialIDToPathMap[mat.uuid]}">${mat.name}</a>`
              if (!e.text) return
              const textContentElement = document.createElement('div')
              textContentElement.innerHTML = e.text
              const textContent = textContentElement.textContent
              if (textContent && textContent !== material.name) e.text = e.text.replace(new RegExp('\\b(' + mat.name + ')\\b', 'gm'), link)
            })
          })

          // Add material property bars
          this.$root.materialProperties.forEach((p, i) => {
            task.taskElementData.propertiesGroup.taskElements.push(p.text + 'Bar')
            task.taskElementData[p.text + 'Bar'] = {
              text: p.text,
              type: 'component',
              component: 'stat-bar',
              value: material[p.value],
              color: p.color,
              marginTop: i ? 3 : 0,
              min: p.min,
              max: p.max,
              icon: p.icon,
            }
          })

          // Fill miscellaneous fields
          Object.values(task.taskElementData).forEach(e => {
            e.count = 0
            e.userTags = []
            e.created = Date.now()
            e.createdBy = CREATOR
          })

          // Fill in advantages
          const numAdvantages = (material.advantages || []).length
          if (!numAdvantages) task.taskElements = task.taskElements.filter(x => x !== 'advantageGroup' && x !== 'advantagesLabel')
          else {
            for (let a = 0; a < numAdvantages; a++) {
              task.taskElementData['advantageTitle' + a] = {
                text: material.advantages[a].title || '',
                marginTop: 0,
                attachments: material.advantages[a].image ? [
                  {
                    uuid: '264b3774-c1d9-4be8-bb4b-d3a2cb4dda39',
                    uploaded: true,
                    height: 150,
                    external: !!material.advantages[a].image.includes('youtube'),
                    name: (material.advantages[a].title || '') + (material.advantages[a].image.includes('youtube') ? '' : '.jpg'),
                    url: material.advantages[a].image || '',
                  },
                ] : [],
                type: 'h3',
              }
              task.taskElementData['advantageText' + a] = {
                text: material.advantages[a].description,
                type: 'text',
              }
              task.taskElementData['advantageGroup' + a] = {
                text: '',
                taskElements: [
                  'advantageTitle' + a,
                  'advantageText' + a,
                ],
                card: false,
                type: 'group',
              }
              task.taskElementData.advantageGroup.taskElements.push('advantageGroup' + a)
            }
          }

          return task
        } else return null
      },
      hasPermission (permission) {
        return this.isAdmin || this.myPermissions.includes(permission)
      },
      hasAnyPermission (permissions) {
        return permissions.some(p => this.hasPermission(p))
      },
      canShowLocation (loc) {
        return !loc || !this.locationView || loc === this.locationView
      },
      canShowAnyLocation (locs) {
        return !locs || !this.locationView || locs.includes(this.locationView)
      },
      downloadPDF: function (project, type, partialModels) {
        const request = {
          url: `${this.apiPath}/documents/${this.enterpriseID}/${project}/${type}`,
          method: 'GET',
          responseType: 'blob',
        }
        if (partialModels) request.params = { quantities: partialModels }
        return Axios(request).then(response => {
          // Get filename
          const basename = response.headers['content-disposition'].split('filename=')[1].split('.')[0]
          const extension = response.headers['content-disposition'].split('.')[1].split(';')[0]
          const filename = `${basename}.${extension}`

          // Download file
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', filename)
          document.body.appendChild(link)
          link.click()
          link.remove()
          this.downloadingQuote = false
        })
      },
      downloadInventoryDataAsCSV: function () {
        const request = {
          url: `${this.apiPath}/documents/${this.enterpriseID}/inventory`,
          method: 'GET',
          responseType: 'blob'
        }
        return Axios(request).then(response => {
          // Get filename
          const basename = response.headers['content-disposition'].split('filename=')[1].split('.')[0]
          const extension = response.headers['content-disposition'].split('.')[1].split(';')[0]
          const filename = `${basename}.${extension}`

          // Download file
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', filename)
          document.body.appendChild(link)
          link.click()
          link.remove()
          this.downloadingCSV = false
        })
      },
      isInTeams: function (teams) {
        const fixedTeams = Array.isArray(teams) ? teams : (teams ? [teams] : [])
        return this.myTeams.some(t => fixedTeams.includes(t.uuid))
      },
      saveAnonymousUserInfo: function () {
        localStorage.setItem('anonymousUserName', this.anonymousUserName)
        localStorage.setItem('anonymousUserEmail', this.anonymousUserEmail)
      },
      callAPI: function (method, path, data) {
        const comp = this
        const idTokenPromise = this.currentUser ? this.currentUser.getIdToken(true) : Promise.resolve('')
        return idTokenPromise.then(idToken => Axios({ method, url: `${comp.apiPath}/${path}`, data, headers: { Authorization: idToken } }))
      },
      unreadMessages: function (chat) {
        if (!this.userID || !chat) return 0
        const messageCounts = (chat.counts || {}).message || 0
        const userReadCounts = ((chat.readCounts || {})[this.userID] || {}).message || 0
        return Math.max(messageCounts - userReadCounts, 0)
      },
      openEmailEditor: function (project, uuid, template) {
        if (project && this.canUseEmailEditor) {
          this.emailEditorShow = true
          this.emailEditorProject = project
          this.emailEditorUUID = uuid
          this.emailEditorTemplate = JSON.parse(JSON.stringify(template || this.management.emailTemplates[0]))
        } else this.emailEditorShow = false
      },
      editQueuedEmail: function () {
        const comp = this
        this.autoEmailConfirmationDownloading = true
        const uuid = this.autoEmailConfirmation.uuid
        const template = this.autoEmailConfirmation.template

        // Edit queued email before sending
        getDoc(doc(this.db, 'enterprises', this.enterpriseID, 'projects', uuid))
          .then(docSnap => {
            comp.openEmailEditor(docSnap.data(), uuid, template)
            comp.autoEmailConfirmation = null
            comp.autoEmailConfirmationDownloading = false
          })
          .catch(err => {
            comp.autoEmailConfirmationDownloading = false
            console.log('Error triggering automatic email', err)
          })
      },
      shrunkImage: function (src, width, height, format) {
        const fbPrefix = 'https://firebasestorage.googleapis.com'
        let suffix = ''
        const transformSegments = []
        if (width) transformSegments.push('w-' + (width * (window.devicePixelRatio || 1)))
        if (height) transformSegments.push('h-' + (height * (window.devicePixelRatio || 1)))
        if (format) transformSegments.push('f-' + format + ',cm-pad_resize')
        if (transformSegments.length) suffix += 'tr:' + transformSegments.join(',')
        return (src || '').replace(fbPrefix, `https://ik.imagekit.io/incept3d/${suffix}`)
      },
      sendQueuedEmail: function () {
        this.autoEmailConfirmationSending = true

        // Send automatic email
        const comp = this
        this.callAPI('post', `send-automatic-email/${this.$root.enterpriseID}`, {
          project: this.autoEmailConfirmation.uuid,
          trigger: this.autoEmailConfirmation.trigger,
          userID: this.$root.currentUser.uid,
        })
        .then(resp => {
          comp.autoEmailConfirmation = null
          comp.autoEmailConfirmationSending = false
        })
        .catch(err => {
          comp.autoEmailConfirmationSending = false
          console.log('Error triggering automatic email', err)
        })
      },
      triggerEmailPrompt: function (triggerKey, projectLabel, uuid) {
        if (this.management && this.management.emailAutomations && this.management.emailAutomations[triggerKey]) {
          this.management.emailAutomations[triggerKey].forEach(trigger => {
            const template = this.management.emailTemplates.find(x => x.uuid === trigger.template)
            if (template) this.autoEmailConfirmationQueue.push({ template, projectLabel, trigger: triggerKey, uuid })
          })
        }
      },
      openMobileNotification: function (notification) {
        // Switch enterprises if on a different enterprise
        const enterprise = notification.enterprise || this.enterpriseID
        if (enterprise !== this.enterpriseID) {
          localStorage.setItem('notificationToOpen', JSON.stringify(notification))
          this.enterpriseOverride = enterprise
          return
        }

        // Otherwise open notification
        this.notificationClicked(notification)
        if (this.currentUser) {
          updateDoc(
            doc(this.db, 'enterprises', this.enterpriseID, 'users', this.currentUser.uid, 'notifications', notification.uuid),
            {
              read: true,
              readTime: Date.now(),
            },
          ).catch(err => console.log('Error marking notification as read', err))
        }
      },
      notificationClicked: function (n) {
        const app = this
        if (n.task) {
          getDoc(doc(this.db, 'enterprises', this.enterpriseID, 'chats', n.task))
            .then(docSnap => {
              this.navigation.popTask = Object.assign({ uuid: docSnap.id }, docSnap.data())
              if (n.taskElement) this.navigation.popTaskElementHighlight = n.taskElement
              else this.navigation.popTaskShowChatOverride = true
              this.navigation.showPopTask = true
            })

          if (this.subscriptions.popTask) this.subscriptions.popTask()
          this.subscriptions.popTask = onSnapshot(
            doc(this.db, 'enterprises', this.enterpriseID, 'chats', n.task),
            docSnap => {
              this.navigation.popTask = Object.assign({ uuid: docSnap.id }, docSnap.data())
            },
            err => console.log('Error getting pop task', err),
          )
        } else if (n.chat) {
          if (n.threadID) {
            this.$root.messageToScrollToChat = n.chat
            this.$root.messageToScrollTo = n.threadID
          }
          this.openChat(n.chat)
          this.showNotifications = false
        } else if (n.project && this.$router.currentRoute.path !== `/projects/${n.project}`) {
          this.$router.push(`/projects/${n.project}`)
        } else if (n.machine) {
          this.openMachinePanel(n.machine)
        } else if (n.externalLink) {
          window.open(n.externalLink, '_blank')
        }
      },
      mergeTwoSorted: function (arr1, arr2, field) {
        const merged = []
        let index1 = 0
        let index2 = 0
        let current = 0
        while (current < (arr1.length + arr2.length)) {
          const isArr1Depleted = index1 >= arr1.length
          const isArr2Depleted = index2 >= arr2.length
          if (!isArr1Depleted && (isArr2Depleted || ((field ? arr1[index1][field] : arr1[index1]) < (field ? arr2[index2][field] : arr2[index2])))) {
            merged[current] = arr1[index1]
            index1++
          } else {
            merged[current] = arr2[index2]
            index2++
          }
          current++
        }
        return merged
      },
      updateMachines: function () {
        if (this.$root.management && this.$root.management.machines) {
          updateDoc(
            doc(this.db, 'enterprises', this.enterpriseID, 'config', 'management'),
            {
              machines: this.$root.management.machines,
              machinesLastUpdatedBy: this.$root.currentUser.uid,
            },
          )
        }
      },
      getJobSettings: function (job, setting) {
        return Array.from(new Set((job.linked || []).map(m => ((m.model || { requestedSettings: {} }).requestedSettings || {})[setting] || 'Unknown')))
      },
      getJobColorName: function (job, setting) {
        return Array.from(new Set((job.linked || []).map(m => this.modelColorName(m.model, m.modelData))))
      },
      getColor: function (color) {
        if (this.catalog) {
          const colors = this.catalog.find(x => x.name === 'Color')
          if (colors) {
            const thisColor = colors.children.find(x => x.name === color)
            if (thisColor) return thisColor
            else return null
          } else return null
        } else return null
      },
      getQrCode: function (value, returnImage) {
        const qr = new QRious({
          value: value,
          size: returnImage || 500,
        })
        return returnImage ? qr.image : qr.toDataURL()
      },
      openReader: function (uuid, element, popup = true) {
        if (uuid) {
          const materialMatch = this.publicTasks.find(x => x.uuid === uuid)
          if (materialMatch) {
            this.readerTask = materialMatch
            if (popup) this.showTaskReader = true
            if (element) this.readerElement = element
          } else {
            getDoc(doc(this.$root.db, 'enterprises', this.$root.enterpriseID, 'chats', uuid))
            .then(snap => {
              if (snap.data()) {
                this.readerTask = Object.assign({ uuid }, snap.data())
                if (popup) this.showTaskReader = true
                if (element) this.readerElement = element
              }
            })
          }
        }
      },
      openChat: function (uuid) {
        // If we hear that the chat opened or mounted, either finish or respond to open
        function receivedFromChat (info) {
          if (info === 'chatOpened') this.$root.$off(uuid, receivedFromChat)
          else if (info === 'mounted') this.$root.$emit(uuid, 'openChat')
        }

        if (uuid) {
          if (uuid.startsWith('project_')) {
            this.$root.$emit(uuid, 'openChat')
            this.$root.$on(uuid, receivedFromChat)
            const projectID = `/projects/${uuid.split('_model_')[0].replace('project_', '')}`
            if (this.$router.currentRoute.path !== projectID) this.$router.push(projectID)
          } else if (uuid.startsWith('publicproject_')) {
            this.$root.$emit(uuid, 'openChat')
            this.$root.$on(uuid, receivedFromChat)
            const projectID = `/projects/${uuid.split('_model_')[0].replace('publicproject_', '')}`
            if (this.$router.currentRoute.path !== projectID) this.$router.push(projectID)
          } else if (uuid.startsWith('designcollection_')) {
            this.$root.$emit(uuid, 'openChat')
            this.$root.$on(uuid, receivedFromChat)
            this.$root.openDesignCollectionChat = uuid.replace('designcollection_', '')
            if (this.$router.currentRoute.path !== `/collection/${uuid}`) this.$router.push(`/collection/${uuid.replace('designcollection_', '')}`)
          } else if (uuid.startsWith('design_')) {
            this.$root.$emit(uuid, 'openChat')
            this.$root.$on(uuid, receivedFromChat)
            if (this.$router.currentRoute.path !== `/design/${uuid}`) this.$router.push(`/design/${uuid.replace('design_', '')}`)
          } else if (uuid.startsWith('printer_')) {
            this.$root.$emit(uuid, 'openChat')
            this.$root.$on(uuid, receivedFromChat)
            this.openMachinePanel(uuid.replace('printer_', ''))
          } else if (uuid.startsWith('job_')) {
            this.$root.$emit(uuid, 'openChat')
            this.$root.$on(uuid, receivedFromChat)
            this.previewJob(uuid.replace('job_', ''))
          } else {
            this.$root.$emit('openChat', uuid)
            this.initialChat = uuid
          }
        } else {
          this.$root.$emit('openChat', null)
          this.initialChat = null
        }
      },
      getUserShortName: function (id, fallback) {
        if (!id) return fallback === undefined ? '...' : fallback
        this.$root.loadUserInfo(id)
        if (this.$root.lookup.users[id].displayName) return this.$root.lookup.users[id].displayName
        if (this.$root.lookup.users[id].fullName) return this.$root.lookup.users[id].fullName.split(' ')[0]
        return fallback === undefined ? '...' : fallback
      },
      getUserFullName: function (id, fallback) {
        if (!id) return fallback === undefined ? '...' : fallback
        this.$root.loadUserInfo(id)
        if (this.$root.lookup.users[id].fullName) return this.$root.lookup.users[id].fullName
        return fallback === undefined ? '...' : fallback
      },
      getUserProfilePic: function (id, fallback) {
        if (!id) return fallback === undefined ? '' : fallback
        this.$root.loadUserInfo(id)
        if (this.$root.lookup.users[id].profilePic) return this.$root.lookup.users[id].profilePic
        return fallback === undefined ? '' : fallback
      },
      getAccountReferenceName: function (id, fallback) {
        if (!id) return fallback === undefined ? '...' : fallback
        this.$root.loadAccountInfo(id)
        if (this.$root.lookup.accounts[id].staffProfile && this.$root.lookup.accounts[id].staffProfile.fullName) return this.$root.lookup.accounts[id].staffProfile.fullName
        if (this.$root.lookup.accounts[id].userProfile && this.$root.lookup.accounts[id].userProfile.fullName) return this.$root.lookup.accounts[id].userProfile.fullName
        return fallback === undefined ? '...' : fallback
      },
      getOrganizationName: function (id, fallback) {
        if (!id) return fallback === undefined ? '...' : fallback
        this.$root.loadOrganizationInfo(id)
        if (this.$root.lookup.organizations[id].name) return this.$root.lookup.organizations[id].name
        return fallback === undefined ? '...' : fallback
      },
      addonPrice: function (addon) {
        let price = addon.rate
        if (!price && addon.prices) {
          const availablePrices = addon.prices.filter(x => x.price && x.minQuantity <= +addon.quantity).map(x => x.price)
          price = Math.min(...availablePrices)
        }
        return +price
      },
      roundToCents: function (num) {
        return (+(Math.round(+(+num * 100)) * 0.01)).toFixed(2)
      },
      totals: function (project, numeric, overrides = {}) {
        const result = {
          models: 0,
          addons: 0,
          itemSubtotal: 0,
          taxableSubtotal: 0,
          discounts: 0,
          minimumOrderFee: 0,
          expeditionFee: 0,
          shippingFee: 0,
          subtotal: 0,
          tax: 0,
          total: 0,
          payments: 0,
          balance: 0,
        }

        if (!project) return result

        // Price models from either instant price or manual price
        if (project.models) {
          project.models.filter(m => !m.staffOnly).forEach(m => {
            const modelPrice = this.getModelUnitPrice(m, project)
            const finalPrice = +this.roundToCents(modelPrice * +m.quantity)
            if (!isNaN(finalPrice)) result.models += finalPrice
          })
        }

        // Add addon prices
        if (project.addons) {
          project.addons.forEach(a => {
            let price = a.rate
            if (!price && a.prices) {
              const availablePrices = a.prices.filter(x => x.price && x.minQuantity <= +a.quantity).map(x => x.price)
              price = Math.min(...availablePrices)
            }
      
            const finalPrice = +this.roundToCents(+price * +a.quantity)
            if (!isNaN(finalPrice)) result.addons += finalPrice
          })
        }

        // Add expedition fees, and calculate subtotal
        if (this.$root.enableAutomatedPricing && this.estimateLeadTime(project).expeditionEligible && project.requestExpedition) {
          result.expeditionFee = this.$root.production.sales.enableExpedition ? +this.$root.production.sales.expeditionFeeCost || 0 : 0
        }
        result.itemSubtotal = result.models + result.addons + result.expeditionFee

        // Calculate miscellaneous order fees
        const onlyProducts = (project.addons || []).filter(a => a.sku).length && !(project.addons || []).filter(a => !a.sku).length && !(project.attachments || []).length && !(project.models || []).length
        if (this.$root.enableAutomatedPricing && !onlyProducts && project.minimumOrder && result.itemSubtotal < project.minimumOrder) {
          result.minimumOrderFee = project.minimumOrder - result.itemSubtotal
        }

        // Calculate subtotals
        result.subtotal = result.itemSubtotal + result.minimumOrderFee
        result.discountedSubtotal = result.subtotal

        // Add discounts
        if (project.discounts && project.discounts.length) {
          for (let d = 0; d < project.discounts.length; d++) {
            // Calculate discount
            const dis = project.discounts[d].value
            let discountValue = 0

            // Parse value against subtotal
            if (dis.endsWith('%')) discountValue = +this.roundToCents((parseFloat(dis) / 100) * result.subtotal)
            else discountValue = parseFloat(dis)

            // Subtract for subtotal and prevent negatives
            if (!isNaN(discountValue)) {
              result.discountedSubtotal -= discountValue
              result.discounts += discountValue
            }
            if (result.discountedSubtotal < 0) {
              result.discountedSubtotal = 0
              result.discounts = result.subtotal
            }
          }
        }

        // Calculate shipping
        const shippingMethod = project.shippingMethod || overrides.shippingMethod
        if (shippingMethod?.amount) {
          result.shippingFee = +shippingMethod.amount
        }
        const extraShipments = (project.shipments || []).filter(s => s.addToTotal && s.rate_details && s.rate_details.amount)
        extraShipments.forEach(s => {
          result.shippingFee += +s.rate_details.amount
        })

        // Calculate taxable total
        result.taxableSubtotal = result.discountedSubtotal + result.shippingFee

        // Calculate taxes
        const taxCalculation = project.checkout?.stripeTaxCalculation || overrides.stripeTaxCalculation
        if (taxCalculation) {
          result.tax = taxCalculation.tax_amount_exclusive / 100
        } else if (project.taxRate?.amount) {
          const taxValue = +this.roundToCents((result.taxableSubtotal * +project.taxRate.amount * 10000).toFixed() / 10000)
          if (!isNaN(taxValue)) result.tax = taxValue
        }
        result.tax -= project.taxReversalAmount || 0

        // Calculate total
        result.total = result.taxableSubtotal + result.tax

        // Calculate balance
        if (project.payments) {
          project.payments.forEach(p => {
            const paymentAmount = +this.roundToCents((p.amount_received + p.amount_capturable) / 100)
            if (!isNaN(paymentAmount)) result.payments += paymentAmount
          })
        }

        // Take into account any Zoho invoice payments
        if (!project.payments && project.invoice && project.invoice.balance < project.invoice.total) {
          result.payments = project.invoice.total - project.invoice.balance
        }

        // Take into account 3D Hubs payments
        if (project.checkout && project.checkout.paymentMethod === '3D Hubs') {
          result.payments = result.total
        }

        // Calculate balance
        result.balance = result.total - result.payments

        // Return in numeric or display format
        Object.keys(result).forEach(k => {
          result[k] = this.roundToCents(result[k])
          if (numeric) result[k] = +result[k]
          else if (result[k] === '-0.00') result[k] = '0.00'
        })
        return result
      },
      projectDescription: function (project) {
        let result = ''
        if (project.models && project.models.length > 0) {
          // Models
          const mLen = project.models.length
          result += mLen + (mLen === 1 ? ' model, ' : ' models, ')

          // Units
          const uLen = project.models.map(mod => +mod.quantity).reduce((a, b) => a + b)
          result += uLen + (uLen === 1 ? ' unit' : ' units')

          // Materials
          const good = project.models.filter(model => model.requestedSettings && model.requestedSettings.Material && model.requestedSettings.Color)
          if (good.length > 0) {
            const mats = [...new Set(good.map(model => this.matchOption('Material', model.requestedSettings.Material, true)?.displayName || model.requestedSettings.Material))]
            const cols = [...new Set(good.map(model => this.matchOption('Color', model.requestedSettings.Color, true)?.displayName || model.requestedSettings.Color))]
            if (mats.length === 1 && cols.length === 1) result += `\n${cols[0]} ${mats[0]}`
            else {
              if (mats.length === 1) result += `\nMixed color ${mats[0]}`
              else if (cols.length === 1) result += `\n${cols[0]} mixed material`
              else result += '\nMixed materials'
            }
          }
        } else if ((project.attachments && project.attachments.length > 0) || (project.addons && project.addons.length)) {
          const lengths = []
          if (project.attachments && project.attachments.length) {
            const attLength = project.attachments.length
            lengths.push(attLength + (attLength === 1 ? ' attachment' : ' attachments'))
          }
          if (project.addons && project.addons.length) {
            const attLength = project.addons.length
            lengths.push(attLength + (attLength === 1 ? ' addon' : ' addons'))
          }
          return lengths.join(', ')
        } else {
          result = 'Empty Project'
        }

        return result
      },
      listenToManagement: function () {
        // Grab current list statuses
        if (this.subscriptions.management) this.subscriptions.management()
        this.subscriptions.management = onSnapshot(
          doc(this.db, 'enterprises', this.enterpriseID, 'config', 'management'),
          docSnap => {
            this.management = docSnap.data()
          },
          err => console.log('Error listening to management snapshot', err),
        )
      },
      listenToMachines: function () {
        // Grab current list statuses
        if (this.subscriptions.machines) this.subscriptions.machines()
        this.subscriptions.machines = onSnapshot(
          query(
            collection(this.db, 'enterprises', this.enterpriseID, 'machines'),
            where('active', '==', true),
          ),
          snap => {
            this.allMachineInstances = snap.docs.map(docSnap => Object.assign(docSnap.data(), { id: docSnap.id }))
          },
          err => console.log('Error listening to machines snapshot', err),
        )
      },
      listenToStaffList: function () {
        // Grab current staff list
        if (this.subscriptions.staff) this.subscriptions.staff()
        this.subscriptions.staff = onSnapshot(
          collection(this.db, 'enterprises', this.enterpriseID, 'staff'),
          docs => {
            const staff = {}
            docs.forEach(docSnap => {
              staff[docSnap.id] = docSnap.data()
              this.loadUserInfo(docSnap.id)
            })
            this.staff = staff
          },
          err => console.log('Error listening to staff snapshot', err),
        )
      },
      listenToStarredNotifications: function () {
        const app = this
        if (this.subscriptions.starredNotifications) this.subscriptions.starredNotifications()
        this.subscriptions.starredNotifications = onSnapshot(
          query(collection(db, 'enterprises', app.enterpriseID, 'users', this.currentUser.uid, 'notifications'), orderBy('created', 'desc'), where('flag', '==', true)),
          docs => {
            const notifications = []
            docs.forEach(docSnap => {
              const notif = docSnap.data()
              notif.uuid = docSnap.id
              notifications.push(notif)
            })
            app.starredUserNotifications = notifications
            app.updateNotifications()
          },
          err => console.log('Error listening to starred notifications snapshot', err),
        )
      },
      listenToUnreadNotifications: function () {
        if (this.subscriptions.unreadNotifications) this.subscriptions.unreadNotifications()
        this.subscriptions.unreadNotifications = onSnapshot(
          query(collection(db, 'enterprises', app.enterpriseID, 'users', this.currentUser.uid, 'notifications'), where('read', '==', false), orderBy('created', 'desc')),
          snap => {
            const notifications = []
            snap.docs.forEach(docSnap => {
              const notif = docSnap.data()
              notif.uuid = docSnap.id
              notifications.push(notif)
            })
            this.unreadUserNotifications = notifications
            this.updateNotifications()
          },
          err => console.log('Error listening to starred notifications snapshot', err),
        )
      },
      listenToNotifications: function () {
        const app = this
        if (this.subscriptions.notifications) this.subscriptions.notifications()
        this.subscriptions.notifications = onSnapshot(
          query(collection(this.db, 'enterprises', this.enterpriseID, 'users', this.currentUser.uid, 'notifications'), orderBy('created', 'desc'), limit(app.notificationAmount * app.notificationPage)),
          snap => {
            if (snap.docs.length >= app.userNotifications.length) {
              const notifications = []
              snap.forEach(docSnap => {
                const notif = docSnap.data()
                notif.uuid = docSnap.id
                notifications.push(notif)
              })
              app.userNotifications = notifications
              app.updateNotifications()
            }
          },
          err => console.log('Error listening to notifications snapshot', err),
        )
      },
      listenToGlobalNotifications: function () {
        if (this.subscriptions.globalNotifications) this.subscriptions.globalNotifications()
        this.subscriptions.globalNotifications = onSnapshot(
          query(collectionGroup(this.db, 'enterprises', this.enterpriseID, 'users', this.currentUser.uid, 'notifications'), orderBy('created', 'desc'), limit(app.notificationAmount * app.notificationPage)),
          snap => {
            if (snap.docs.length >= app.userNotifications.length) {
              const notifications = []
              snap.forEach(docSnap => {
                const notif = docSnap.data()
                notif.uuid = docSnap.id
                notifications.push(notif)
              })
              app.userNotifications = notifications
              app.updateNotifications()
            }
          },
          err => console.log('Error listening to notifications snapshot', err),
        )
      },
      listenToTeams: function () {
        const app = this
        if (this.subscriptions.teams) this.subscriptions.teams()
        this.subscriptions.teams = onSnapshot(
          query(
            collection(this.db, 'enterprises', this.enterpriseID, 'teams'),
            where('active', '==', true),
            orderBy('created'),
          ),
          snap => {
            app.allTeams = snap.docs.map(x => Object.assign({ uuid: x.id }, x.data())).concat([{ uuid: 'public', name: 'Public' }])
            app.listenToTasks()
          },
          err => console.log('Error listening to teams snapshot', err),
        )
      },
      listenToTasks: function () {
        // Listen to tasks that are available to the user/user's teams
        // Because the list of teams might be more than 10, we'll need to split the query into multiple queries

        if (this.subscriptions.tasks) this.subscriptions.tasks.forEach(x => x())
        this.subscriptions.tasks = []
        const memberIDs = (this.currentUser ? [this.currentUser.uid] : ['public']).concat(this.myTeams.map(x => x.uuid))
        
        // Split memberIDs into groups of 10
        const memberIDGroups = []
        while (memberIDs.length > 0) memberIDGroups.push(memberIDs.splice(0, 10))

        this.userTaskGroups = []
        memberIDGroups.forEach((memberIDGroup, i) => {
          this.subscriptions.tasks[i] = onSnapshot(
            query(
              collection(this.db, 'enterprises', this.enterpriseID, 'chatsMinified'),
              or(
                and(
                  where('type', '==', 'task'),
                  where('taskUsers', 'array-contains-any', memberIDGroup),
                  where('active', '==', true),
                ),
                and(
                  where('active', '==', true),
                  where('taggedUsers', 'array-contains-any', memberIDGroup),
                )
              )
            ),
            snapshot => {
              this.userTaskGroups.splice(i, 1, snapshot.docs.map(d => Object.assign(d.data(), { uuid: d.id })))
            },
            err => console.log('Error listening to user tasks snapshot of member group', memberIDGroup, err),
          )
        })
      },
      logout: function () {
        if (this.currentUser.uid && this.notificationToken) {
          setDoc(
            doc(this.db, 'users', this.currentUser.uid, 'devices', this.notificationToken),
            { active: false, dateModified: Date.now() },
            { merge: true },
          ).catch(err => console.log('Error logging out of device', err))
        }
        if (this.isStaff) {
          this.machines = []
        }
        this.state.settingsOpen = false
        signOut(this.auth)
          .then(() => { this.enterpriseOverride = null })
          .catch(err => console.log(err))
      },
      updateNotifications: function () {
        if (app.showNotifications) {
          app.userNotifications.forEach(n => {
            if (!n.read) {
              updateDoc(doc(app.db, 'enterprises', app.enterpriseID, 'users', app.currentUser.uid, 'notifications', n.uuid), {
                read: true,
                readTime: Date.now(),
              }).catch(err => console.log('Error marking notification as read', err))
            }
          })
        }
      },
      readUnreadNotifications: function () {
        this.unreadUserNotifications.forEach(n => {
          if (!n.read) {
            updateDoc(doc(this.db, 'enterprises', this.enterpriseID, 'users', this.currentUser.uid, 'notifications', n.uuid), {
              read: true,
              readTime: Date.now(),
            }).catch(err => console.log('Error marking notification as read', err))
          }
        })
      },
      loadAccountInfo: function (id) {
        if (id && !this.$root.lookup.accounts[id]) {
          app.$set(this.$root.lookup.accounts, id, {})
          const data = this
          if (!this.$root.subscriptions['account' + id]) {
            this.$root.subscriptions['account' + id] = onSnapshot(
              doc(this.db, 'enterprises', this.enterpriseID, 'accounts', id),
              docSnap => {
                this.$root.lookup.accounts[id] = docSnap.data()
              },
              err => console.log('Error in downloading account data:', err))
          }
        }
      },
      loadUserInfo: function (uuid) {
        if (uuid && !this.$root.lookup.users[uuid]) {
          app.$set(this.$root.lookup.users, uuid, {})
          const data = this
          if (!this.$root.subscriptions['user' + uuid]) {
            this.$root.subscriptions['user' + uuid] = onSnapshot(
              doc(this.db, 'enterprises', this.enterpriseID, 'users', uuid),
              docSnap => {
                this.$root.lookup.users[uuid] = docSnap.data()
              },
              err => console.log('Error in downloading user data:', err))
          }
        }
      },
      loadOrganizationInfo: function (id) {
        if (id && !this.$root.lookup.organizations[id]) {
          this.$set(this.$root.lookup.organizations, id, {})
          if (!this.$root.subscriptions['organization' + id]) {
            this.$root.subscriptions['organization' + id] = onSnapshot(
              doc(this.db, 'enterprises', this.enterpriseID, 'organizations', id),
              docSnap => {
                this.$root.lookup.organizations[id] = docSnap.data()
              },
              err => console.log('Error in downloading organization data:', err))
          }
        }
      },
      loadRoomInfo: function (uuid) {
        if (uuid && !this.$root.lookup.chatRooms[uuid]) {
          this.$set(this.$root.lookup.chatRooms, uuid, { uuid: uuid })
          if (!this.$root.subscriptions['chat' + uuid]) {
            this.$root.subscriptions['chat' + uuid] = onSnapshot(
              doc(this.db, 'enterprises', this.enterpriseID, 'chats', uuid),
              docSnap => {
                this.$root.lookup.chatRooms[uuid] = Object.assign(docSnap.data() || {}, { uuid: uuid })
              },
              err => console.log('Error in downloading chat room data:', err),
            )
          }
        }
      },
      getJob: function (id) {
        const emptyReturn = {}
        if (id) {
          if (this.$root.lookup.jobs[id]) return this.$root.lookup.jobs[id]
          else if (this.$root.subscriptions['job' + id]) return emptyReturn
          else {
            const app = this
            this.$root.subscriptions['job' + id] = onSnapshot(
              doc(this.db, 'enterprises', this.enterpriseID, 'jobs', id),
              docSnap => {
                this.$set(this.$root.lookup.jobs, id, Object.assign({ uuid: docSnap.id }, docSnap.data()))
              },
              err => console.log('Error getting job', err),
            )
            return emptyReturn
          }
        } else return emptyReturn
      },
      getUser: function (id) {
        const emptyReturn = {}
        if (id) {
          if (this.$root.lookup.users[id]) return this.$root.lookup.users[id]
          else if (this.$root.subscriptions['user' + id]) return emptyReturn
          else {
            const app = this
            this.$root.subscriptions['user' + id] = onSnapshot(
              doc(this.db, 'enterprises', this.enterpriseID, 'users', id),
              docSnap => {
                this.$set(this.$root.lookup.users, id, Object.assign({ uuid: docSnap.id }, docSnap.data()))
              },
              err => console.log('Error getting user', err),
            )
            return emptyReturn
          }
        } else return emptyReturn
      },
    },
    render: h => h(App),
  }).$mount('#app')
}

export {
  app,
}
